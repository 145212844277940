import React, { Fragment } from "react";
import { __getImage } from "../../../_factory/globalService";
import { InputText } from "primereact/inputtext";
const PasswordInput = ({
    input,
    width,
    placeholder,
    isDisabled,
    maxlength,
    minlength,
    leftIcon = '',
    className = '',
    addonclassName = '',
    label,
    meta: { touched, error }
}) => {
    const [passwordType, set_passwordType] = React.useState(true);
    const togglePasswordType = () => {
        set_passwordType(!passwordType)
    }
    return (
        <Fragment>
            <div className={`addon_group ${addonclassName ? addonclassName : ''}`}>
                {leftIcon && <img src={__getImage(leftIcon)} />}
                <span className="p-float-label width_100">
                    <InputText
                        {...input}
                        placeholder={placeholder}
                        type={passwordType ? 'password' : 'text'}
                        width={width}
                        maxLength={maxlength}
                        minLength={minlength}
                        readOnly={isDisabled}
                        className={`form-control ${className ? className : ''} ${touched && error ? " is-invalid focus-visible" : ""
                            }`}
                    />
                    <label htmlFor="username">{label || placeholder}</label>
                </span>
                {passwordType ? <img className='cursor' src={__getImage('fi_eye-off.png')} onClick={() => { togglePasswordType() }} /> : <img className='cursor' src={__getImage('fi_eye-on.png')} onClick={() => { togglePasswordType() }} />}
            </div>
            {touched && error && (
                <span className="error error-feedback">{error}</span>
            )}
        </Fragment>
    );
};

export default PasswordInput;