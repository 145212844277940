const EmployeeMenuData = [
    {
        ds_nm: 'Dashboard',
        url: '/dashboard/employer',
        icon:'fa fa-tachometer',
        activeMenuFor: ['/dashboard/employer']
    },
    {
        ds_nm: 'Job Listing',
        url: '/job/listing',
        icon:'fa fa-list',
        activeMenuFor: ['/job/listing']
    },
    {
        ds_nm: 'Applied Students List',
        url: '/job/listing/applied',
        icon:'fa fa-list',
        activeMenuFor: ['/job/listing/applied']
    },
    {
        ds_nm: 'Reporting Manager',
        url: '/reporting-manager/list',
        icon: 'fa fa-users',
        activeMenuFor: ['/reporting-manager/list']
    },
    // {
    //     ds_nm: 'Assessment',
    //     url: '',
    //     icon:'fa fa-book',
    //     activeMenuFor: ['']
    // }
]
export default EmployeeMenuData