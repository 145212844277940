import React from 'react';
import Card from '../../../Components/Card';
import style from './carrierTile.module.scss';
import { __stringToWord } from '../../../_factory/globalService';
const EducationTpl = (product) => {
    let dataArr = [];
    if (product && product.educations && product.educations.length > 0) {
        dataArr = product.educations
    }
    if (product && product.workExperiances && product.workExperiances.length > 0) {
        dataArr = product.workExperiances
    }
    return (
        <div className={style['carrier_tile']}>
            <Card>
                <div className={`row-same-height ${style['carrier_match']}`}>
                    <div className={`col-same-height ${style['left_cell']}`}>
                        <div className='content'>
                            <div className={style['name']}>
                                {product && product.title}
                            </div>
                            <div className={style['detail']}>
                                <div className='row'>
                                    {dataArr && dataArr.length > 0 && dataArr.map((item, index) => {
                                        let keys = Object.keys(item);
                                        keys = keys && keys[0]
                                        return (
                                            <div key={`ar_${index}`} className='col-sm-6'>
                                                <div className={`d-flex ${style['detail']}`}>
                                                    <div className={style['position']}>{__stringToWord(keys)} : &nbsp;</div>
                                                    <div className={style['positionValue']}>{item[keys]}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
export default EducationTpl;