import React from 'react';
import { __getImage } from '../../_factory/globalService'
const CustomButton = ({ type = 'button', text = '', onclick = null, className = '', iconLeft = '', iconRight = '' }) => {
    const handleBtnClick = (e) => {
        if (onclick) {
            return onclick(e)
        }
    }
    return (<button type={type} onClick={(e) => { handleBtnClick(e) }} className={`btn custom_btn ${className ? className : ''}`}>
        {iconLeft ? <>
            <img src={__getImage(iconLeft)} />
            &nbsp; &nbsp;
        </> : ''}
        {text}
        {iconRight ? <>
            &nbsp; &nbsp;
            <img src={__getImage(iconRight)} />
        </> : ''}
    </button>)
}
export default CustomButton;