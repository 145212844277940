import { apiConfig } from '../../_factory/apiConfig';
import { HttpFetch, HttpPost } from '../../_factory/http-common';
export const __httpEnrolmentList = async (body, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.student.list;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}


export const __httpEnrolmentDetail = async (id, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.student.detail.replace('{id}', id);
        const res = await HttpFetch(url, {})
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const __httpStudentAssignment = async (id, callback, errorCallback) => {
    try {
        let url = `${apiConfig.apiUrls.student.studentAssignment}${id}`;
        const res = await HttpFetch(url, {})
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

/* assignmentDetail */
export const __httpStudentAssignmentDetail = async (id, callback, errorCallback) => {
    try {
        let url = `${apiConfig.apiUrls.student.assignmentDetail}${id}`;
        const res = await HttpFetch(url, {})
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const __httpAssignmentMaster = async (callback, errorCallback) => {
    try {
        let url = `${apiConfig.apiUrls.student.assignmentMaster}`;
        const res = await HttpFetch(url, {})
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

