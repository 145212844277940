import { useNavigate, useParams } from 'react-router-dom';
import { __localStorageGet } from '../_factory/globalService';
import SideNavBar from '../Components/SideNavBar';
import { routerConfig } from '../_factory/routerConfig';
import { NavData } from '../Components/SideNavBar/Data';
import Header from '../Components/Header';
import { Sidebar } from 'primereact/sidebar';
import { useState } from 'react';

const LayoutWithSideNav = ({ authGuard, children, isSideNav, isByPassLogin }) => {
    const userData = __localStorageGet('userDataTM');
    const _jwt = userData && userData.token;
    const loginType = userData && userData.type;
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const renderSideBar = () => {

        return (
            <>
                <div className='isMobileNav'>
                    <Sidebar visible={visible} onHide={() => setVisible(false)}>
                        <SideNavBar />
                    </Sidebar>
                </div>
                <div className='isDesktopNav'>
                    <SideNavBar />
                </div>

                {/* <CustomButton type='button' text={'Save'} className='btn_next' iconLeft="iconSave.svg" onClick={() => setVisible(true)} /> */}
            </>
        )
    }
    const HandleAllowedRoutes = () => {
        return (
            <>
                <div className={`page_wrapper ${isSideNav ? ' side_nav' : ''}`}>
                    {isSideNav && renderSideBar()}
                    <div className='page_content'>
                        <Header showSideMenu={() => setVisible(!visible)} />
                        {children}
                    </div>
                </div>
            </>
        )
    }

    const HandleNotAllowedRoutes = () => {
        let _url = routerConfig.login.route;
        setTimeout(() => {
            navigate(_url)
        }, 100)
    }
    if (isByPassLogin) {
        if (!_jwt) {
            return HandleNotAllowedRoutes()
        } else {
            return HandleAllowedRoutes()
        }
    } else {
        if (!authGuard) {
            return HandleAllowedRoutes()
        } else if (authGuard && !_jwt) {
            return HandleNotAllowedRoutes()
        } else {
            let isAuthorize = false;
            let tempMenuData = NavData[loginType];
            tempMenuData.map((item) => {
                if (item.url && item.url != '/') {
                    if (window.location.href.indexOf(item.url) > -1) {
                        isAuthorize = true
                        return
                    }
                } else {
                    isAuthorize = false
                }
            })
            if (isAuthorize) {
                return HandleAllowedRoutes()
            } else {
                return HandleNotAllowedRoutes()
            }

        }
    }
}

export default LayoutWithSideNav;