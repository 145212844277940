import React from "react";
const TaskDimensionsTable = ({renderValue, renderFullName}) => {
    return (
        <div className='table_wrapper auto'>
            <table>
                <thead>
                    <tr>
                        <th >   {"Task dimensions"}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td >
                            {renderValue('I') > renderValue('F') ? renderFullName('I') : renderFullName('F')}
                        </td>
                    </tr>
                    <tr>
                        <td >
                            {renderValue('SP') > renderValue('D') ? renderFullName('SP') : renderFullName('D')}
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default TaskDimensionsTable;