import React from 'react';
import style from './index.module.scss';
import CustomButton from '../../../Components/form/CustomButton';
import CarrierMatchTpl from './CarrierMatchTpl';
import AssessmentDetail from './AssessmentDetail';
import { __httpAssignmentMaster, __httpEnrolmentDetail, __httpStudentAssignment, __httpStudentAssignmentDetail } from '../enrolmentService';
import { __getImage, __getImageurl, __getLoginDetail, __localStorageGet } from '../../../_factory/globalService';
import { ProgressBar } from 'primereact/progressbar';
import { Carousel } from 'primereact/carousel';
import { useParams } from 'react-router-dom';
import AssessmentReport from './Report';
import EducationTpl from './EducationTpl';
import Card from '../../../Components/Card';
import PersonalityCodeTable from './Report/PersonalityCodeTable';
import PresonalityChartTable from './Report/PresonalityChartTable';
import IDPGDataTable from './Report/IDPGDataTable';
import { PresonalityTitlesdata } from './data';
const EnrolmentReportDetail = ({ ...props }) => {
    const params = useParams();
    const [detail, set_detail] = React.useState(null);
    const [aptitudeCodeMaster, set_aptitudeCodeMaster] = React.useState(null);
    const [personalityCodeMaster, set_personalityCodeMaster] = React.useState(null);
    const [motivationCodeCodeMaster, set_motivationCodeCodeMaster] = React.useState(null);

    const userData = __localStorageGet('userDataTM');
    const loginType = userData && userData.type;


    React.useEffect(() => {

        if (params && params.studentId.length > 1) {
            getDetail(params.studentId);
        }
    }, []);

    const getDetail = (id) => {
        __httpEnrolmentDetail(id).then(res => {
            const responseData = res.data.responseData;
            getAssessmentDetail(params.studentId)
            set_detail(responseData)
        })
    }

    const getAssessmentDetail = (id) => {
        __httpStudentAssignmentDetail(id).then(res => {
            let temp_aptitudeCodeMaster = null;
            let temp_personalityCodeMaster = null;
            let temp_motivationCodeCodeMaster = null;
            const responseData = res && res.data && res.data.responseData;
            if (responseData) {
                responseData.map((item, index) => {
                    if (item) {
                        if (item.categoryName == 'Aptitude Code') {
                            temp_aptitudeCodeMaster = item
                        }
                        if (item.categoryName == 'Personality Code') {
                            temp_personalityCodeMaster = item
                        }
                        if (item.categoryName == 'Motivation Code') {
                            temp_motivationCodeCodeMaster = item
                        }
                    }
                })
                set_aptitudeCodeMaster(temp_aptitudeCodeMaster);
                set_personalityCodeMaster(temp_personalityCodeMaster);
                set_motivationCodeCodeMaster(temp_motivationCodeCodeMaster);
            }
        })
    }
    const calculateIQ = () => {
        let list = aptitudeCodeMaster && aptitudeCodeMaster.results;
        let totalWeightageCount = 0;
        list.map((item) => {
            totalWeightageCount += parseInt(item.totalWeightage)
        })
        return totalWeightageCount;
    }
    const renderValue = (key) => {
        let temp;
        let list = personalityCodeMaster && personalityCodeMaster.results;
        let value = key && key.toLowerCase();
        list.map((item) => {
            let shortName = item.shortName && item.shortName.toLowerCase();
            if (shortName == value) {
                temp = item.totalWeightage
            }
        })
        return temp;
    }
    const renderDiffValue = (maxKey, minKey) => {
        let list = personalityCodeMaster && personalityCodeMaster.results;
        let tempMaxKey = maxKey && maxKey.toLowerCase();
        let tempMinKey = minKey && minKey.toLowerCase();
        let a = 0, b = 0;
        list.map((item) => {
            let shortName = item.shortName && item.shortName.toLowerCase();
            if (shortName == tempMaxKey) {
                a = item.totalWeightage;
            }
            if (shortName == tempMinKey) {
                b = item.totalWeightage;
            }
        })
        return a - b
    }
    const renderFullName = (key) => {
        let temp;
        let list = personalityCodeMaster && personalityCodeMaster.results;
        let value = key && key.toLowerCase();
        list.map((item) => {
            let shortName = item.shortName && item.shortName.toLowerCase();
            if (shortName == value) {
                temp = item.subCategory
            }
        })
        return temp;
    }

    const bindPresonalityValue = (start, end, position, type = "asc") => {
        let returnValue = "";
        if (type === "desc") {
            for (let i = end; i >= start; i--) {
                const showValue = i * 5;
                const lastValue = ((i - 1) * 5)
                if (showValue === position) {
                    returnValue += "X "
                } else {
                    returnValue += showValue + " "
                    if (position > lastValue && showValue > position) {
                        returnValue += "X "
                    }

                }

            }
        } else {
            for (let i = start; i <= end; i++) {
                const showValue = i * 5;
                const lastValue = ((i + 1) * 5)
                if (showValue === position) {
                    returnValue += "X "
                } else {
                    returnValue += showValue + " "
                    if (lastValue > position && showValue < position) {
                        returnValue += "X "
                    }

                }
            }
        }
        return returnValue;
    }

    const renderImage = (profilePic) => {
        if (profilePic) {
            let url = __getImageurl('student_profile', profilePic)
            return (<img className={style['detail_pick']} src={url} />)
        } else {
            return (<i class="fa fa-user" aria-hidden="true" style={{ 'fontSize': '30px' }}></i>)
        }
    }

    const renderTitle = () => {
        const row1 = renderValue('SO') > renderValue('G') ? 'SO' : 'G'
        const row2 = renderValue('A') > renderValue('P') ? 'A' : 'P'
        const row3 = renderValue('I') > renderValue('F') ? 'I' : 'F'
        const row4 = renderValue('S') > renderValue('D') ? 'SP' : 'D'
        const key = row3 + row4 + row2 + row1
        let filterArray = PresonalityTitlesdata.filter((pt) => pt.key === key)
        return (
            <>
                {
                    filterArray.length > 0 &&

                    < div >
                        <div className={style['reportHeading']}>{filterArray[0].name}</div>
                        <div className={style['reportBox']}>
                            {filterArray[0].description.split('\n').map((line) => <div style={{ 'margin-top': '15px' }}>{line} </div>)}
                        </div>
                    </div >
                }
            </>

        );
    }

    return (<>
        {aptitudeCodeMaster ? <>
            <div className={`row ${style['top_bar']}`}>
                <div className='col-sm-12 col-md-6'>
                    <div className='d-flex'>
                        <div className={`ml-10 d-flex align-items-center`}>
                            {renderImage(detail?.profilePic || '')}
                            <div className='ml-10'>
                                <p className={style['user_name']}>{detail?.firstName} {detail?.lastName}</p>
                                <p className={style['user_un']}>{detail?.department}</p>
                                <p className={style['user_des']}>{detail?.position}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='col-sm-12 col-md-6'>
                    <div className='d-flex align-items-center justify-content-end'>
                    </div>
                </div>
            </div>

            <div className={`row row-same-height align-items-center ${style['skill_assesment']}`}>
                <div className='col-same-height col-sm-12 col-md-10'>
                    <div className='content'>
                        <div>
                            <div className={style['reportHeading']}> {personalityCodeMaster?.categoryName}</div>
                            <div className={`auto ${style['reportTableBox']}`}>
                                <table >
                                    <thead>
                                        <tr>
                                            <th className="text-center" colSpan={2}>Total Scores</th>
                                            <th className="text-center">Dominant Letter</th>
                                            <th className="text-center">Score Difference</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center">
                                                SO = {renderValue('SO')}
                                            </td>
                                            <td className="text-center">
                                                G = {renderValue('G')}
                                            </td>
                                            <td className="text-center">

                                                {renderValue('SO') > renderValue('G') ? 'SO' : 'G'}
                                            </td>
                                            <td className="text-center">
                                                {renderValue('SO') > renderValue('G') ? renderDiffValue('SO', 'G') : renderDiffValue('G', 'SO')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                A = {renderValue('A')}
                                            </td>
                                            <td className="text-center">
                                                P = {renderValue('P')}
                                            </td>
                                            <td className="text-center">

                                                {renderValue('A') > renderValue('P') ? 'A' : 'P'}
                                            </td>
                                            <td className="text-center">
                                                {renderValue('A') > renderValue('P') ? renderDiffValue('A', 'P') : renderDiffValue('P', 'A')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                I = {renderValue('I')}
                                            </td>
                                            <td className="text-center">
                                                F = {renderValue('F')}
                                            </td>
                                            <td className="text-center">
                                                {renderValue('I') > renderValue('F') ? 'I' : 'F'}

                                            </td>
                                            <td className="text-center">
                                                {renderValue('I') > renderValue('F') ? renderDiffValue('I', 'F') : renderDiffValue('F', 'I')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                SP = {renderValue('S')}
                                            </td>
                                            <td className="text-center">
                                                D = {renderValue('D')}
                                            </td>
                                            <td className="text-center">
                                                {renderValue('S') > renderValue('D') ? 'SP' : 'D'}
                                            </td>
                                            <td className="text-center">
                                                {renderValue('S') > renderValue('D') ? renderDiffValue('D', 'D') : renderDiffValue('D', 'S')}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='col-same-height col-sm-12 col-md-2'>
                    <div className='content'>
                        <div className='isMobileNav'>
                            <div className={'mt-20'}></div>
                        </div>
                        <div className={`${style['skill_resume']}`}>
                            <div className='d-flex align-items-center'>
                            </div>
                            <div className={`d-flex flex-wrap `}>
                                <Card>
                                    <div className={`row-same-height ${style['carrier_match']}`}>

                                        <div className='content text-center'>
                                            <div style={{ height: '99%' }}>
                                                <br />
                                                <br />
                                                {calculateIQ()}
                                                <br />
                                                <br />
                                            </div>
                                            <div className='btn custom_btn btn_next' style={{ width: '100%', fontWeight: 'bold' }}>
                                                IQ Score
                                            </div>
                                        </div>

                                    </div>
                                </Card>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className={`row row-same-height align-items-center ${style['skill_assesment']}`}>
                <div className='col-same-height col-sm-12 col-md-8'>
                    <div className='content'>
                        <div>
                            <>
                                <div className={style['reportHeading']}>Presonality Chart</div>

                                <div className={`auto ${style['reportTableBox']}`}>
                                    <table >
                                        <tbody>
                                            <tr>
                                                <td>  SO {renderFullName("SO")}</td>
                                                <td style={{ 'padding': '14px 10px' }}> {bindPresonalityValue(2, 10, renderValue('SO') > renderValue('G') ? renderValue('SO') : 0, "desc")}

                                                </td>

                                                <td className="text-right" style={{ 'padding': '14px 10px' }}>{bindPresonalityValue(2, 10, renderValue('SO') <= renderValue('G') ? renderValue('G') : 0)} &nbsp;</td>
                                                <td className="text-right">  G {renderFullName("G")}</td>
                                            </tr>
                                            <tr>
                                                <td>  A {renderFullName("A")}</td>
                                                <td style={{ 'padding': '14px 10px' }}> {bindPresonalityValue(2, 10, (renderValue('A') > renderValue('P') ? renderValue('A') : 0), "desc")}
                                                </td>
                                                <td className="text-right" style={{ 'padding': '14px 10px' }}>{bindPresonalityValue(2, 10, (renderValue('A') <= renderValue('P') ? renderValue('P') : 0))} </td>
                                                <td className="text-right">  P {renderFullName("P")}</td>
                                            </tr>

                                            <tr>
                                                <td>  I {renderFullName("I")}</td>
                                                <td style={{ 'padding': '14px 10px' }}> {bindPresonalityValue(2, 10, (renderValue('I') > renderValue('F') ? renderValue('I') : 0), "desc")}
                                                </td>
                                                <td className="text-right" style={{ 'padding': '14px 10px' }}>{bindPresonalityValue(2, 10, (renderValue('I') <= renderValue('F') ? renderValue('F') : 0))} </td>
                                                <td className="text-right">  F {renderFullName("F")}</td>
                                            </tr>
                                            <tr>
                                                <td>  SP {renderFullName("S")}</td>
                                                <td style={{ 'padding': '14px 10px' }}> {bindPresonalityValue(2, 10, (renderValue('S') > renderValue('D') ? renderValue('S') : 0), "desc")}
                                                </td>
                                                <td className="text-right" style={{ 'padding': '14px 10px' }}>{bindPresonalityValue(2, 10, (renderValue('S') <= renderValue('D') ? renderValue('D') : 0))} </td>
                                                <td className="text-right">  D {renderFullName("D")}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <br />
                            </>
                        </div>
                    </div>
                </div>
                <div className='col-same-height col-sm-12 col-md-2'>
                    <div className='content'>
                        <div className={`${style['skill_resume']}`}>
                            <div className='d-flex align-items-center'>
                            </div>
                            <div className={`d-flex flex-wrap `}>
                                <Card>
                                    <div className={`row-same-height ${style['carrier_match']}`}>
                                        <div className='content text-center'>
                                            <div style={{ height: '100%' }}>
                                                <br />
                                                <br />
                                                {renderValue('SO') > renderValue('G') ? renderFullName('SO') : renderFullName('G')}
                                                <br />
                                                <br />
                                                {renderValue('A') > renderValue('P') ? renderFullName('A') : renderFullName('P')}
                                                <br />
                                                <br />
                                            </div>
                                            <div className='btn custom_btn btn_next' style={{ width: '100%', fontWeight: 'bold' }}>
                                                People dimensions
                                            </div>
                                        </div>

                                    </div>
                                </Card>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-same-height col-sm-12 col-md-2'>
                    <div className='content'>
                        <div className='isMobileNav'>
                            <div className={'mt-20'}></div>
                        </div>
                        <div className={`${style['skill_resume']}`}>
                            <div className='d-flex align-items-center'>
                            </div>
                            <div className={`d-flex flex-wrap `}>
                                <Card>
                                    <div className={`row-same-height ${style['carrier_match']}`}>

                                        <div className='content text-center'>
                                            <div style={{ height: '100%' }}>
                                                <br />
                                                <br />
                                                {renderValue('I') > renderValue('F') ? renderFullName('I') : renderFullName('F')}
                                                <br />
                                                <br />
                                                {renderValue('SP') > renderValue('D') ? renderFullName('SP') : renderFullName('D')}
                                                <br />
                                                <br />
                                            </div>
                                            <div className='btn custom_btn btn_next' style={{ width: '100%', fontWeight: 'bold' }}>
                                                Task dimensions
                                            </div>
                                        </div>

                                    </div>
                                </Card>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className={`row row-same-height align-items-center ${style['skill_assesment']}`}>
                <div className='col-same-height col-sm-12 col-md-8'>
                    <div className='content'>
                        <div>
                            {renderTitle()}
                        </div>
                    </div>
                </div>
                <div className='col-same-height col-sm-12 col-md-2'>
                    <div className='content'>
                    </div>
                </div>
                <div className='col-same-height col-sm-12 col-md-2'>
                    <div className='content'>
                    </div>
                </div>
            </div>

        </> : 'No Records'}
    </>)
}
export default EnrolmentReportDetail;