import { apiConfig } from '../../_factory/apiConfig';
import { HttpFetch, HttpPost, HttpPostForm } from '../../_factory/http-common';
export const __httpUpdateProfile = async (body, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.profile.update;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const __httpProfileGet = async (id, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.profile.detail.replace('{id}', id);
        const res = await HttpFetch(url, {})
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const __httpProfilePicUpdate = async (e, id, callback, errorCallback) => {
    try {
        let uploadFormData = new FormData();
        uploadFormData.append("profilepic", e.target.files[0]);
        uploadFormData.append("id", id);
        let url = apiConfig.apiUrls.profile.profilepicUpload;
        const res = await HttpPostForm(url, uploadFormData)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}