import React from 'react';
import Card from '../../../../Components/Card';
import { __getImage, __getLoginDetail, __startEndIndex } from '../../../../_factory/globalService';
import style from '../../listinig.module.scss';
import { __httpAllUserList } from '../../../../_factory/globalMasterService';
import AccountFormComponent from '../AccountFormComponent';
import AccountDetailComponent from '../AccountDetailComponent';
import Paging from '../../../../Components/Paging';
import { Dropdown } from 'primereact/dropdown';
import { accountType } from '../../../../_factory/_constants';
import TableSearchComponent from '../../../../Components/TableSearch';
const AccountManagementComponent = () => {
    const [listData, set_listData] = React.useState(null);
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [masterDialogConfig, set_masterDialogConfig] = React.useState(null);
    const [viewDetailConfig, set_viewDetailConfig] = React.useState(null);
    const [activePage, set_activePage] = React.useState(0);
    const [perPage] = React.useState(10);
    const [startIndex, set_startIndex] = React.useState(0);
    const [maxIndex, set_maxIndex] = React.useState(0);
    const [searchParam, set_searchParam] = React.useState({ "name": "", "type": "" });
    const [searchInput, set_searchInput] = React.useState('');
    const [filteredListData, set_filteredListData] = React.useState(null);
    React.useEffect(() => {
        set_isDataLoaded(true)
    }, [])
    React.useEffect(() => {
        const startEndIndex = __startEndIndex(activePage, perPage);
        set_startIndex(startEndIndex?.startIndex);
        set_maxIndex(startEndIndex?.endIndex)
    }, [activePage])

    React.useEffect(() => {
        filterListingData(searchInput)
    }, [listData])

    const getListingData = (body) => {
        let temp = [];
        __httpAllUserList(body).then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = []
            }
            set_listData(temp)
            set_isDataLoaded(true)
        })
    }
    const filterListingData = (val = '') => {
        let filteredData = [];
        if (val === "") {
            filteredData = listData ||[];
        } else {
            filteredData = (listData||[]).filter((item) => {
                let name = item.name.toLowerCase() ||'';
                return name.includes(val.toLowerCase());
            });
        }
        set_filteredListData(filteredData)
    }
    const handleSearch = (value) => {
        set_searchInput(value);
        filterListingData(value)
    }
    const openForm = () => {
        let temp = {
            isOpen: true,
            title: `Add Account`,
            type: 'add',
            formValue: {
                "name": "",
                "email": "",
                "password": "",
                "type": ""
            }
        }
        set_masterDialogConfig(temp)
    }
    const closeMasterFormDialog = (e) => {
        if (e && e.isReload) {
            getListingData(searchParam);
        }
        set_masterDialogConfig({})
    }
    const viewAccountInfo = (value) => {
        let config = {
            isOpen: true,
            title: 'View Account Detail',
            formValue: {
                ...value
            }
        }
        set_viewDetailConfig(config)
    }
    const closeViewDialog = () => {
        set_viewDetailConfig(null)
    }
    const editAccountInfo = (ele) => {
        let temp = {
            isOpen: true,
            title: `Edit Account`,
            type: 'edit',
            formValue: {
                "name": ele?.name,
                "email": ele?.email,
                "password": ele?.password,
                "type": ele?.type,
                "referralCode": ele?.referralCode,
                "id": ele?.id
            }
        }
        set_masterDialogConfig(temp)
    }
    const handleOnPaginationCallback = (pageNumber) => {
        set_activePage(pageNumber)
    }
    const handleTypeChange = (e) => {
        let temp = {
            ...searchParam,
            "type": e
        }
        set_searchParam(temp)
        getListingData(temp)
    }
    const handleClearFilter = () => {
        let value = '';
        let temp = {
            ...searchParam,
            "type": ''
        }
        set_searchParam(temp)
        set_searchInput(value);
        getListingData(temp)
    }
    return (<>
        <Card>
            <div className={style['enrollment_wrapper']}>
                <div className='form_wrapper'>
                    <div className={'plr-25'}>
                        <div className='row'>
                            <div className='col-sm-12 col-md-9'>
                                <div className='row'>
                                    <div className='col-md-4 col-sm-12'>
                                        <div className='form-group'>
                                            <span className="p-float-label">
                                                <Dropdown options={accountType} value={searchParam?.type} optionLabel="name" placeholder="Account Type" className="form-control" onChange={(e) => handleTypeChange(e.value)} showClear={searchParam?.type} />
                                                <label>Account Type</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-sm-12'>
                                        <div className='form-group'>
                                            <TableSearchComponent placeholder='Search by name' searchInput={searchInput} handleSearch={handleSearch} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-3'>
                                <div className=''>
                                    <div className='d-flex plr-10 justify-content-end width_100'>
                                        {/* <button type='button' className='btn custom_btn btn_next no-wrap'>
                                            <img src={__getImage('filtersearch.svg')} />
                                            Filter
                                        </button> */}
                                        <button type='button' className={`ml-10 btn custom_btn btn_transparent no-wrap`} onClick={() => { handleClearFilter() }}>
                                            <img src={__getImage('filtersearch.svg')} />
                                            &nbsp;
                                            Clear Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div>
                        <div className={style['page_header']}>
                            <div>
                                <div className={style['page_title']}>
                                    Account Management
                                </div>
                                {isDataLoaded && filteredListData && <div className={style['count']}>{filteredListData.length} Record{filteredListData.length > 9 ? 's' : ''}</div>}
                            </div>
                            <div className='d-flex'>
                                <button type='button' className={`ml-10 ${style['btn_primary']}`} onClick={() => { openForm(null) }}>
                                    <img src={__getImage('exportsquare.svg')} />
                                    New
                                </button>
                            </div>
                        </div>
                        <div className='table_wrapper scrollBar'>
                            <table id="saleReportTable">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className='content no-wrap'>
                                                Si. No.
                                            </div>
                                        </th>
                                        <th>
                                            <div className='content'>
                                                Name
                                            </div>
                                        </th>
                                        <th>
                                            <div className='content'>
                                                Type
                                            </div>
                                        </th>
                                        <th>
                                            <div className='content text-center'>
                                                Action
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isDataLoaded ? <tr>
                                        <td colSpan={100}>
                                            <div className='content'>

                                            </div>
                                        </td>
                                    </tr> : <>
                                        {filteredListData && filteredListData.length > 0 ? <>
                                            {filteredListData && filteredListData.slice(startIndex, maxIndex).map((ele, i) => {
                                                return (
                                                    <tr key={`en_${i}`}>
                                                        <td style={{ 'width': '100px' }}>
                                                            {(perPage * activePage) + (i + 1)}
                                                        </td>
                                                        <td>
                                                            <div className='content'>
                                                                {ele.name}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='content'>
                                                                {ele.type}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='d-flex justify-content-center'>
                                                                <div className='mr-10 pointer content' onClick={() => { editAccountInfo(ele) }}>
                                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                                </div>
                                                                <div className='pointer content' onClick={() => { viewAccountInfo(ele) }}>
                                                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </> : <>
                                            <tr>
                                                <td colSpan={100}>
                                                    <div className='content'>
                                                        No records...
                                                    </div>
                                                </td>
                                            </tr>
                                        </>}
                                    </>}
                                </tbody>
                            </table>
                        </div>
                        {isDataLoaded && filteredListData && filteredListData.length > 0 ? <div className='pagers'>
                            <div></div>
                            <Paging total={filteredListData.length} perPage={perPage} activePage={activePage} onPaginationCallback={handleOnPaginationCallback} />
                        </div> : ''}
                    </div>
                </div>
            </div>
        </Card>
        {masterDialogConfig && masterDialogConfig.isOpen && <AccountFormComponent data={masterDialogConfig} onCloseCallback={closeMasterFormDialog} />}
        {viewDetailConfig && viewDetailConfig.isOpen && <AccountDetailComponent data={viewDetailConfig} onCloseCallback={closeViewDialog} />}
    </>)
}
export default AccountManagementComponent;