import React from 'react';
import Card from '../../../Components/Card';
import style from './carrierTile.module.scss';
const CarrierMatchTpl = (product) => {
    let jobDetail = '';
    if (product && product.jobDetail && product.jobDetail.length > 0) {
        jobDetail = product.jobDetail[0]
    }
    return (
        <div className={style['carrier_tile']}>
            <Card>
                <div className={`row-same-height ${style['carrier_match']}`}>
                    <div className={`col-same-height ${style['left_cell']}`}>
                        <div className='content'>
                            <div className={style['name']}>
                                {product && product.name}
                            </div>
                            <div className={style['detail']}>
                                {jobDetail.title ? <div className={style['position']}>{jobDetail.title}</div> : ''}


                                {jobDetail.qualifications ? <>
                                    <div className={`d-flex ${style['detail']}`}>
                                        <div className={style['position']}>Qualifications : &nbsp;</div>
                                        <div className={style['positionValue']}>{jobDetail?.qualifications}</div>
                                    </div>
                                </> : ''}

                                {jobDetail.location ? <div className={`d-flex ${style['detail']}`}>
                                    <div className={style['position']}>Location: &nbsp;</div>
                                    <div className={style['positionValue']}>{jobDetail?.location}</div>
                                </div> : ''}

                                {jobDetail.responsbilities ?
                                    <div className={`${style['desc']} ${style['detail']}`}>
                                        {jobDetail.responsbilities}
                                        {jobDetail.responsbilities && jobDetail.responsbilities.length > 100 && <span className={style['more']}>know more</span>}
                                    </div> : ''}
                            </div>
                            {jobDetail.minSalary && jobDetail.maxSalary ? <>
                                <div className={style['salary_range']}>Salary Range Rs. {jobDetail.minSalary} - to Rs. {jobDetail.minSalary} Lakh</div>
                            </> : ''}
                        </div>
                    </div>
                    <div className={`col-same-height ${style['right_cell']}`}>
                        <div className='content'>
                            <div className={style['popular_card']}>
                                <div className={style['card_inner']}>
                                    <span className={style['score']}>{(product.matchPercentage)}%</span>
                                    <span className={style['match_text']}>{product.match}</span>
                                </div>
                            </div>
                            {/* <CustomButton text="Apply now" className='btn_next width_100'></CustomButton> */}
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
export default CarrierMatchTpl;