import { createValidator } from "revalidate";
export const customValidations = {
    hasNumbers: function (myString) {
        return /\d/.test(myString);
    },
    stringCheck: function (myString) {
        return isNaN(myString);
    },
    minValue: (n) =>
        createValidator(
            (message) => (value) => {
                if (value && Number(value) < n) {
                    return message;
                }
            },
            (field) => `${field} Should be greater than  ${n}`
        ),
    maxValue: (n) =>
        createValidator(
            (message) => (value) => {
                if (value && Number(value) > n) {
                    return message;
                }
            },
            (field) => `${field} Should not be greater than  ${n}`
        ),
    minLength: (n) =>
        createValidator(
            (message) => (value) => {
                if (value && value.length < n) {
                    return message;
                }
            },
            (field) => `${field} Should be ${n} digits`
        ),
    maxLength: (n) =>
        createValidator(
            (message) => (value) => {
                if (value && value.length > n) {
                    return message;
                }
            },
            (field) => `${field} Should be ${n} digits`
        ),
    fixedLength: (n) =>
        createValidator(
            (message) => (value) => {
                if (value && (value.length < n || value.length > n)) {
                    return message;
                }
            },
            (field) => `${field} Should be ${n} digits`
        ),
    isValidEmail: createValidator(
        (message) => (value) => {
            if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                return message;
            }
        },
        "Invalid email address"
    ),
    onlyText: createValidator(
        (message) => (value) => {
            const isInvalid = customValidations.hasNumbers(value);
            if (value && isInvalid) {
                return message;
            }
        },
        "Please enter alphabet characters only"
    ),
    toBeString: createValidator(
        (message) => (value) => {
            const valid = customValidations.stringCheck(value);
            if (value && !valid) {
                return message;
            }
        },
        "Please enter alphabet characters"
    ),

    composeValidatorsField:
        (...validators) =>
            (value) =>
                validators.reduce(
                    (error, validator) => error || validator(value),
                    undefined
                ),
    minValueField: (min, field) => (value) =>
        isNaN(value) || value >= min
            ? undefined
            : `${field} Should be greater than ${min}`,
    mustBeNumber: (field) => (value) =>
        isNaN(value) ? `${field} Must be a number` : undefined,
    required: (field) => (value) =>
        value ? undefined : `${field} is required`,
};

export default customValidations;
