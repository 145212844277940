import React from 'react';
import Navigation from './Navigation';
import { createBrowserHistory } from 'history';
import { ProgressSpinner } from 'primereact/progressspinner';
export const history = createBrowserHistory();
const App = () => {
  return (
    <div className="App">
        <div className='loader_wrap' id="loader">
        <div className='loader_wrap-inner'>
          <ProgressSpinner />
        </div>
      </div>
      <div className='loader_wrap' id="loader2">
        <div className='loader_wrap-inner'>
          <ProgressSpinner />
        </div>
      </div>
      <Navigation history={history} />
    </div>
  );
}
export default App;