import React from 'react';
import Card from '../../Components/Card';
import CustomButton from '../../Components/form/CustomButton';
import { Editor } from 'primereact/editor';
import { __getImage } from '../../_factory/globalService';
import { __getMasterList } from '../../_factory/globalMasterService';
import { __httpSaveQuestion } from './QuestionService';
import { useNavigate, useParams } from 'react-router-dom';
import StatusDialog from '../../Components/Dialog/StatusDialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { answerType } from '../../_factory/_constants';
const QuestinFormAddWrapper = ({ qs, _QuestionDetailReducer = {}, initialForm = {}, categoryMaster, carrierMaster, ...props }) => {
    let navigate = useNavigate();
    const params = useParams();
    const [initialValue, set_initialValue] = React.useState(initialForm);
    const [tagMaster, set_tagMaster] = React.useState([]);
    const [savedQuestion, set_savedQuestion] = React.useState('');
    const [savedAnswers, set_savedAnswers] = React.useState([{ "answer": "", "weightage": "", "tag": "" }]);
    const [isFormSubmit, set_isFormSubmit] = React.useState(null);
    const [statusDialogConfig, set_statusDialogConfig] = React.useState(null);
    const [isDataloaded, set_isDataloaded] = React.useState(false)
    React.useEffect(() => {
        if (params && params.id == 0) {
            set_isDataloaded(true)
        }
    }, []);
    React.useEffect(() => {
        if (_QuestionDetailReducer) {
            setFormInitialValue()
        }
    }, [_QuestionDetailReducer]);
    React.useEffect(() => {
        let temp = {
            ...initialValue,
            answers: savedAnswers
        }
        set_initialValue(temp)
    }, [savedAnswers])
    React.useEffect(() => {
        let temp = {
            ...initialValue,
            question: savedQuestion
        }
        set_initialValue(temp)
    }, [savedQuestion])

    const setFormInitialValue = () => {
        let temp = {
            ...initialForm
        }
        for (const key in initialForm) {
            const hasKey = _QuestionDetailReducer.hasOwnProperty(key);
            if (hasKey && _QuestionDetailReducer[key]) {
                temp[key] = _QuestionDetailReducer[key]
            }
        }
        setTimeout(() => {
            set_initialValue(temp);
            setEditorValue(temp)
            set_isDataloaded(true)
        }, 100)
    }
    const setEditorValue = (data) => {
        let question = data.question;
        let answers = data.answers;
        set_savedQuestion(question)
        set_savedAnswers(answers)
    }
    const handleOnClickCallBack = () => {
        set_statusDialogConfig(null)
    }
    const createTagMaster = (id) => {
        var index = categoryMaster.map((category) => { return category.id; }).indexOf(id);
        if (index >= 0) {
            if (categoryMaster[index]) {
                let temp = categoryMaster[index] && categoryMaster[index]['subItems'];
                set_tagMaster(temp || [])
            }
        }
    }
    const handleCategoryChange = (e) => {
        let id = e.target.value;
        set_tagMaster([]);
        if (id) {
            createTagMaster(id)
            var index = categoryMaster.map((category) => { return category.id; }).indexOf(id);
            if (index >= 0) {
                let temp = {
                    ...initialValue,
                    subCategory: '',
                    categoryId: id,
                    category: categoryMaster[index]['name']
                }
                set_initialValue(temp)
            } else {
                let temp = {
                    ...initialValue,
                    subCategory: '',
                    categoryId: '',
                    category: ''
                }
                set_initialValue(temp)
            }

        }
    }
    const handleSubCategoryChange = (e) => {
        let id = e.target.value;
        let temp = {
            ...initialValue,
            subCategory: id
        }
        set_initialValue(temp)
    }

    const handleCarrierChange = (e) => {
        let id = e.target.value;
        if (id) {
            var index = carrierMaster.map((carrier) => { return carrier.id; }).indexOf(id);
            if (index >= 0) {
                let temp = {
                    ...initialValue,
                    carrier: carrierMaster[index]['name'],
                    carrierId: id,
                }
                set_initialValue(temp)
            } else {
                let temp = {
                    ...initialValue,
                    categoryId: '',
                    category: ''
                }
                set_initialValue(temp)
            }
        }
    }
    const handleInputChange = (e, isNumber) => {
        let key = e.target.id;
        let value = e.target.value;
        if (isNumber) {
            value = e.target.value.replace(/[^0-9\.]/g, '');
            if (value && value.split('.').length > 2) {
                value = value.replace(/\.+$/, "");
            }
        }
        let temp = {
            ...initialValue,
        }
        temp[key] = value;
        set_initialValue(temp)

    }

    const handleAnswerTypeChange = (e, data) => {
        let id = e.target.value;
        let temp = {
            ...initialValue,
            answerType: id
        }
        set_initialValue(temp)
    }
    const handleQuestionOnChange = (e, data) => {
        let value = e.htmlValue || '';
        set_savedQuestion(value)
    }
    const handleAnswerOnChange = (e, index) => {
        const value = e.htmlValue;
        let temp = [...savedAnswers];
        if (temp.length == 0) {
            let item = {
                "answer": value,
                "weightage": "",
                "tag": ""
            }
            temp.push(item)
        } else {
            if (temp[index]) {
                temp[index]['answer'] = e.htmlValue
            } else {
                let item = {
                    "answer": value,
                    "weightage": "",
                    "tag": ""
                }
                temp.push(item)
            }
        }
        set_savedAnswers(temp)

    }

    const handleInputArrayChange = (e, index, isNumber) => {
        let value = e.target.value || '';
        let key = e.target.id;
        if (isNumber) {
            value = e.target.value.replace(/[^0-9\.]/g, '');
            if (value && value.split('.').length > 2) {
                value = value.replace(/\.+$/, "");
            }
        }
        let temp = {
            ...initialValue
        }
        if (temp && temp.answers && temp.answers.length > 0) {
            temp.answers[index][key] = value
        }
        set_initialValue(temp)
    }

    const handleAddFormElement = () => {
        let item = {
            "answer": "",
            "weightage": '',
            "tag": ""
        }
        let temp = [...savedAnswers]
        if (temp && temp.length > 0) {
            temp.push(item)
        } else {
            temp = [];
            temp.push(item)
        }
        set_savedAnswers(temp)
    }
    const handleRemoveFormElement = (i) => {
        let temp = {
            ...initialValue
        }
        if (temp && temp.answers && temp.answers.length > 0) {
            let answers = temp.answers;
            answers.splice(i, 1);
            temp.answers = answers
        } else {
            temp.answers = [];
        }
        set_initialValue(temp)
    }
    const resetFormData = () => {
        let temp = {
            ...initialForm
        }
        set_initialValue(temp)
        navigate(`/question/listing`);
    }
    const createData = (values) => {
        let temp = {
            ...values,
            questionCount: 0,
        }
        if (params && params.id.length > 1) {
            temp.id = params?.id
        }
        return temp
    }
    const checkValidation = () => {
        let errors = document.querySelectorAll(".questionForm");
        if (errors && errors.length > 0) {
            return false
        } else {
            return true
        }
    }
    const onSubmit = () => {
        set_isFormSubmit(true);
        setTimeout(() => {
            let payload = createData(initialValue);
            const isValidForm = checkValidation();
            if (isValidForm) {
                __httpSaveQuestion(payload).then(res => {
                    let config = {}
                    if (res && res?.data) {
                        if (res?.data?.isSuccess) {
                            config = {
                                body: res.data.responseMessage || 'Data saved successfully',
                                isSuccess: true,
                                isFooter: false
                            }
                            set_statusDialogConfig(config)
                            setTimeout(() => {
                                set_statusDialogConfig(null);
                                navigate(`/question/listing`);
                            }, 2000)
                        } else {
                            config = {
                                body: res?.data?.responseMessage || 'Something went wrong',
                                isSuccess: false,
                                isFooter: true
                            }
                            set_statusDialogConfig(config)
                        }
                    } else {
                        config = {
                            body: 'Something went wrong',
                            isSuccess: false,
                            isFooter: true
                        }
                        set_statusDialogConfig(config)
                    }
                })
            } else {
                return
            }
        }, 300)
    }
    return (
        <>
            <Card>
                {/* <ReactJsonView data={savedAnswers} />
                <ReactJsonView data={savedQuestion} />
                <ReactJsonView data={initialValue} /> */}
                {isDataloaded ? <div className={qs['card_wrapper_inner']}>
                    <div className='row form-group'>
                        <div className='col-sm-8'>
                            <div className='form-group'>
                                <div className='p-float-label form-control-wrap'>
                                    <Dropdown options={categoryMaster} value={initialValue.categoryId} optionLabel="name" optionValue="id" className="form-control" placeholder='Select Category' onChange={(e) => handleCategoryChange(e)} />
                                    <label>Select Category</label>
                                </div>
                                {isFormSubmit && !initialValue.categoryId && <div className="questionForm error error-feedback">
                                    <span>Field is Required</span>
                                </div>}
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='form-group'>
                                <div className='p-float-label form-control-wrap'>
                                    <Dropdown options={tagMaster} value={initialValue.subCategory} optionLabel="name" optionValue="name" className="form-control" placeholder='Select Sub Category' onChange={(e) => handleSubCategoryChange(e)} />
                                    <label>Select Sub Category</label>
                                </div>
                                {isFormSubmit && !initialValue.subCategory && <div className="questionForm error error-feedback">
                                    <span>Field is Required</span>
                                </div>}
                            </div>
                        </div>
                        <div className='col-sm-8'>
                            <div className='form-group'>
                                <div className='p-float-label form-control-wrap'>
                                    <Dropdown options={carrierMaster} value={initialValue.carrierId} optionLabel="name" optionValue="id" className="form-control" placeholder='Select Carrier' onChange={(e) => handleCarrierChange(e)} />
                                    <label>Select Career</label>
                                </div>
                                {isFormSubmit && !initialValue.carrierId && <div className="questionForm error error-feedback">
                                    <span>Field is Required</span>
                                </div>}
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='form-group'>
                                <div className='form-control-wrap p-float-label'>
                                    <InputText className='form-control' id="timelimit" value={initialValue.timelimit} onChange={(e) => handleInputChange(e, true)} />
                                    <label>Time Limit</label>
                                </div>
                                {isFormSubmit && !initialValue.timelimit && <div className="questionForm error error-feedback">
                                    <span>Field is Required</span>
                                </div>}
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='form-group'>
                                <div className='p-float-label form-control-wrap'>
                                    <Dropdown options={answerType} value={initialValue.answerType} optionLabel="name" optionValue="value" className="form-control" placeholder='Select Sub Category' onChange={(e) => handleAnswerTypeChange(e, initialValue)} />
                                    <label>Select Answer Type</label>
                                </div>
                                {isFormSubmit && !initialValue.answerType && <div className="questionForm error error-feedback">
                                    <span>Field is Required</span>
                                </div>}
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div class="form-group">
                                <label>
                                    Question
                                    <span>:</span>
                                </label>
                                <div className='form-control-wrap'>
                                    <Editor style={{ height: '350px' }} value={savedQuestion} onTextChange={(e) => handleQuestionOnChange(e)} />
                                </div>
                                {isFormSubmit && !savedQuestion && <div className="questionForm error error-feedback">
                                    <span>Field is Required</span>
                                </div>}
                            </div>
                        </div>
                        {initialValue && initialValue.answers && initialValue.answers.length > 0 ? <>
                            {initialValue.answers.map((answer, ansIndex) => {
                                return (
                                    <div className='mb-20 width_100'>
                                        <Card>
                                            <div className='row' style={{ 'padding': '0 20px' }}>
                                                <div className='col-sm-8'>
                                                    <div class="form-group">
                                                        <label>
                                                            Answer - {ansIndex + 1}
                                                            <span>:</span>
                                                        </label>
                                                        <div className='form-control-wrap'>
                                                            <Editor style={{ height: '250px' }} value={savedAnswers[ansIndex].answer} onTextChange={(e) => handleAnswerOnChange(e, ansIndex)} />
                                                        </div>
                                                        {isFormSubmit && !savedAnswers[ansIndex].answer && <div className="questionForm error error-feedback">
                                                            <span>Field is Required</span>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <div className='form-group'>
                                                        <label className='placeholder'>
                                                            Weightage
                                                            <span>:</span>
                                                        </label>
                                                        <div className='form-control-wrap p-float-label'>
                                                            <InputText className='form-control' id="weightage" value={savedAnswers[ansIndex].weightage} onChange={(e) => handleInputArrayChange(e, ansIndex, true)} />
                                                            <label>Weightage</label>
                                                        </div>
                                                    </div>
                                                    <div className='form-group'>
                                                        <div className='form-control-wrap p-float-label'>
                                                            <InputText className='form-control' id="tag" value={savedAnswers[ansIndex].tag} onChange={(e) => handleInputArrayChange(e, ansIndex)} placeholder='Tag' />
                                                            <label>Tag</label>
                                                        </div>
                                                        {isFormSubmit && !savedAnswers[ansIndex].tag && <div className="questionForm error error-feedback">
                                                            <span>Field is Required</span>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-left pl-10'>
                                                {initialValue && initialValue.answers && initialValue.answers.length - 1 == ansIndex && <button type='button' className='btn custom_btn btn_next mr-10' onClick={() => { handleAddFormElement() }}>
                                                    <img src={__getImage('iconAdd.svg')} />
                                                    &nbsp; &nbsp;
                                                    Add
                                                </button>}

                                                {initialValue && initialValue.answers && initialValue.answers.length > 1 && <button type='button' className='btn custom_btn btn_next' onClick={() => { handleRemoveFormElement(ansIndex) }}>
                                                    Remove
                                                </button>}
                                            </div>
                                        </Card>
                                    </div>
                                )
                            })}
                        </> : ''}
                    </div>
                    <div className="form-group">
                        <div className=" text-right">

                            <div className='form-group txt-center'>
                                <div className='d-flex justify-content-end'>
                                    <CustomButton type='button' text={'Save'} className='btn_next' iconLeft="iconSave.svg" onclick={onSubmit} />
                                    <button type='button' className='btn custom_btn ml-10 btn_next' onClick={(e) => {
                                        resetFormData();
                                    }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ''}
            </Card>
            {statusDialogConfig && <StatusDialog body={statusDialogConfig.body} isTitle={true} isSuccess={statusDialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={statusDialogConfig?.isFooter} />}
        </>
    )
}

export default QuestinFormAddWrapper;