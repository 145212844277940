import { HttpPost } from "../../_factory/http-common";
import { apiConfig } from "../../_factory/apiConfig"
export const _httpSignUp = async (body, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.auth.signup;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const _httpLogin = async (body, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.auth.login;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }
    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}