import React from 'react';
import CardMatchReportUnversity from './components/DashboardCard/CardMatchReportUnversity';
import CardReport from './components/DashboardCard/CardReport';
const DashboardAdmin = ({ studentInfo, styles, topDataReport, jobInfo }) => {
    return (
        <>
            <div className={`row row-same-height ${styles['graph_sep']}`}>
                <div className='col-same-height col-sm-12 col-md-8'>
                    <div className='content'>
                        <CardMatchReportUnversity data={topDataReport} />
                    </div>
                </div>
                <div className='col-same-height col-sm-12 col-md-4'>
                    <div className='content d-flex justify-content-between flex-column'>
                        <div className='isMobileNav'>
                            <div className={'mt-20'}></div>
                        </div>
                        <div>
                            <CardReport data={jobInfo} />
                        </div>

                        <div className={'mt-20'}>
                            <CardReport data={studentInfo} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DashboardAdmin;