import React from 'react';
import { __getImage } from '../../../../_factory/globalService';
import style from './index.module.scss';
const SuperAdminCardMatchReport = ({ data }) => {
    return (<div className={`height_100 ${style['match_card']}`}>
        <div className={`height_100 ${style['match_card_inner']}`}>
            <div className='height_100 d-flex justify-content-between'>
                <div className={style['left_cell']}>
                    <img src={__getImage('/matchCardTile.svg')} />
                </div>
                <div className={`d-flex flex-column justify-content-between ${style['right_cell']}`}>
                    <table className={style['match_card_table']}>
                        <thead>
                            <tr>
                                <th>
                                    <div className='isMobileNav'>
                                        Ques Categories
                                    </div>
                                    <div className='isDesktopNav'>
                                        Top Questions Categories
                                    </div>
                                </th>
                                <th>
                                    <div className='isMobileNav'>
                                        Total No.
                                    </div>
                                    <div className='isDesktopNav'>
                                        Total Number
                                    </div>

                                </th>
                            </tr>
                        </thead>
                        {data && data.length > 0 && data.slice(0, 5).map((ele, i) => {
                            return (
                                <tr key={`crd_${i}`}>
                                    <td className={style['position']}>
                                        {ele && ele[0] && (ele[0]).category}
                                    </td>
                                    <td>
                                        <div className='d-flex'>
                                            <span className={style['icon_diamond']}></span>
                                            <span>
                                                <span className={style['total']}>{ele.length}</span>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                    {/* <div className={style['card-footer']}>
                        <CustomButton text="View Applications" className='btn_next' />
                        <CustomButton text="View Interviews" className='ml-10 btn_transparent' />
                    </div> */}
                </div>
            </div>
        </div>
    </div>)
}
export default SuperAdminCardMatchReport;