import { apiConfig } from "../../_factory/apiConfig";
import { HttpFetch, HttpPost } from "../../_factory/http-common";

export const __httpFetchRM = async (id, body, callback, errorCallback) => {
    try {
        let url = `${apiConfig.apiUrls.reportingManager.list}${id}`;
        const res = await HttpFetch(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }
    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const __deleteReportingManager = async (body, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.reportingManager.delete;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const __saveReportingManager = async (body, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.reportingManager.save;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const __detailReportingManager = async (id, callback, errorCallback) => {
    try {
        let url = `${apiConfig.apiUrls.reportingManager.detail}${id}`;
        const res = await HttpFetch(url, {})
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}
export const __resendReportingManagerMail = async (id, callback, errorCallback) => {
    try {
        let url = `${apiConfig.apiUrls.reportingManager.resendEmail}${id}`;
        const res = await HttpFetch(url, {})
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}