import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
const PolarAreaChart = ({ data }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    useEffect(() => {
        const textColor = '#fff';
        const surfaceBorder = '#fff';
        const options = {
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };
        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className="justify-content-center">
            <Chart type="polarArea" data={chartData} options={chartOptions} style={{ width: '400px', 'margin': '0px auto' }} />
        </div>
    )
}
export default PolarAreaChart;