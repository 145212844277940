import React from 'react';
import DialogWrapper from '../../../Components/Dialog/DialogWrapper';
import { Field, Form } from 'react-final-form';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import InputText from '../../../Components/form/InputText';
import ErrorMessage from '../../../Components/form/ErrorMessage';
import CustomButton from '../../../Components/form/CustomButton';
import InputTextAreaComponent from '../../../Components/form/InputTextAreaComponent';
import SelectInput from '../../../Components/form/SelectInput';
import { __getImage, __getLoginDetail } from '../../../_factory/globalService';
import { __httpJobPost } from '../JobServicec';
import { __getMasterList } from '../../../_factory/globalMasterService';
import { __httpFetchRM } from '../../ReportingManager/rmService';
const JobPostForm = ({ data, onCloseCallback }) => {
    const [initialValue, set_initialValue] = React.useState({});
    const [employeeTypeMaster, set_employeeTypeMaster] = React.useState([])
    const [industryMaster, set_industryMaster] = React.useState([]);
    const [rmMaster, set_rmMaster] = React.useState([]);
    const [userInfo, set_userInfo] = React.useState(null);
    React.useEffect(() => {
        let userData = __getLoginDetail();
        set_userInfo(userData)
        Get_EmployeeTypeMaster()
        Get_IndustryMaster()
        Get_RM_Master()
    }, [])
    React.useEffect(() => {
        if (data && data.formValue) {
            set_initialValue(data.formValue)
        }
    }, [data]);
    React.useEffect(() => {
        Get_RM_Master({})
    }, [userInfo])
    const Get_EmployeeTypeMaster = async () => {
        let body = {
            "type": 'employeeType'
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_employeeTypeMaster(responseData)
    }
    const Get_RM_Master = async () => {
        if (userInfo && userInfo.id) {
            let res = await __httpFetchRM(userInfo.id, {});
            let temp = [];
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = []
            }
            set_rmMaster(temp)
        }
    }
    const Get_IndustryMaster = async () => {
        let body = {
            "type": 'career'
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_industryMaster(responseData)
    }
    const validate = combineValidators({
        title: composeValidators(
            isRequired,
        )("title")
    });
    const onSubmit = async (values) => {
        let userData = __getLoginDetail();
        let payload = {
            ...values,
            employerId: userData?.id
        }
        const res = await __httpJobPost(payload);
        let temp = {};
        if (res && res.data && res.data.isSuccess) {
            temp = {
                isOpen: false,
                isReload: true,
                data: ''
            }
        } else {
            temp = {
                isOpen: true,
                isReload: false,
                data: ''
            }
        }
        handleOnClickCallBack(temp)
    }

    const handleOnClickCallBack = (e) => {
        onCloseCallback(e)
    }
    return (<>
        <DialogWrapper className={'width_900'}>
            <div className='modal-header'>
                <h5 className="modal-title">{data.title}</h5>
                <div className='dialog-close'>
                    <img src={__getImage('/dialog_close.svg')} onClick={(e) => { handleOnClickCallBack(null) }} />
                </div>
            </div>

            <div className='modal-body'>
                <div className='form_wrapper'>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={initialValue || {}}
                        validate={validate}
                        render={({
                            handleSubmit,
                            submitError,
                            values,
                            dirtySinceLastSubmit,
                        }) => (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <div className='row'>
                                    <div className='col-sm-12 col-md-4'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="title"
                                                component={InputText}
                                                placeholder="Job title"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="qualifications"
                                                component={InputText}
                                                placeholder="Qualifications"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4'>
                                        <div className="form-group">
                                            <Field
                                                component={SelectInput}
                                                name="industryId"
                                                placeholder="Industry"
                                                options={industryMaster}
                                                nameKey={'name'}
                                                valueKey='id'
                                                isShowClear={values?.industryId}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4'>
                                        <div className="form-group">
                                            <Field
                                                component={SelectInput}
                                                name="employeeTypeId"
                                                placeholder="Employee type"
                                                options={employeeTypeMaster}
                                                nameKey={'name'}
                                                valueKey='id'
                                                isShowClear={values?.employeeTypeId}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4'>
                                        <div className="form-group">
                                            <Field
                                                component={SelectInput}
                                                name="reportingId"
                                                placeholder="Reporting Manager"
                                                options={rmMaster}
                                                nameKey={'name'}
                                                valueKey='id'
                                                isShowClear={values?.reportingId}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="location"
                                                component={InputText}
                                                placeholder="Location"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="minSalary"
                                                component={InputText}
                                                placeholder="Min Salary"
                                            />
                                        </div>
                                    </div>

                                    <div className='col-sm-12 col-md-4'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="maxSalary"
                                                component={InputText}
                                                placeholder="Max Salary"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-12'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="responsbilities"
                                                component={InputTextAreaComponent}
                                                placeholder="Job description & responsibilities"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className=" text-right">
                                        {submitError && !dirtySinceLastSubmit && (
                                            <ErrorMessage
                                                error={submitError}
                                                text="Error during submit your form."
                                            />
                                        )}
                                        <div className='form-group txt-center'>
                                            <CustomButton type={'submit'} text='Post Job' className='btn_next' />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
        </DialogWrapper>
    </>)
}
export default JobPostForm;