import React from 'react';
import { __getImage } from '../../_factory/globalService';
const StatusDialog = ({ isTitle, isSuccess, footer, body, onClickCallBack }) => {
    const handleClick = (e) => {
        if (onClickCallBack) {
            let data = {
                ...e,
                status: isSuccess
            }
            onClickCallBack(data)
        }
    }
    return (
        <div className='dialog_wrapper'>
            <div className='dialog_inner'>
                <div className='dialog_content'>
                    {isTitle && <div className={`modal_title ${isSuccess ? 'success' : 'error'}`}>
                        <div >
                            <img src={__getImage(isSuccess ? 'success_logo.svg' : 'error_logo.svg')} />
                        </div>
                        <div className='title_text'>
                            {isSuccess ? 'SUCCESS' : 'ERROR!'}
                        </div>
                    </div>}
                    {body && <div className='modal-body text-center'>
                        {body}
                    </div>}
                    {footer && <div className='bodal-footer'>
                        <div className='d-flex justify-content-center'>
                            {<button className={`btn ${isSuccess ? 'btn-ok' : 'btn-no'}`} type='button' onClick={(e) => { handleClick(e) }}>{isSuccess ? 'Continue' : 'Try Again'}</button>}
                        </div>

                    </div>}
                </div>
            </div>
        </div>
    )
}
export default StatusDialog;