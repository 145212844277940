export const apiConfig = {
    apiBaseUrl: process.env.REACT_APP_API_URL,
    apiUrls: {
        auth: {
            login: '/api/auth/login',
            signup: '/api/user/signup',
        },
        student: {
            list: '/api/student/list',
            import: '/api/student/import',
            detail: 'api/student/detail/{id}',
            studentAssignment:`/api/student/assignment/top5/`,
            assignmentDetail:`/api/student/assignment/detail/`,
            assignmentMaster:`/api/student/assignment/masters`,
        },
        job: {
            listing: '/api/job/list',
            detail: '/api/job/detail/{id}',
            save: '/api/job/save',
            update: '/api/job/complete/job',
            appliedJobs: 'api/job/student/applied/{id}',
        },
        profile: {
            update: `/api/user/profile/update`,
            detail: `/api/user/detail/{id}`,
            profilepicUpload:`/api/user/profilepic/upload`
        },
        user: {
            allUser: '/api/user/list'
        },
        master: {
            list: '/api/master/list',
            save: '/api/master/save',
        },
        question: {
            'save': `/api/question/save`,
            'list':'/api/question/list',
        },
        reportingManager:{
            'save': `/api/reporting/save`,
            'detail': `/api/reporting/detail/`,
            'list':'/api/reporting/list/',
            'delete':'/api/reporting/delete',
            'resendEmail':`/api/reporting/reSendMail/`
        }
    }
};
