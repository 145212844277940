import React from "react";
import DialogWrapper from "../../../../Components/Dialog/DialogWrapper";
import { __getImage } from "../../../../_factory/globalService";
import PolarAreaChart from "../../../../Components/Chart/PolarArea";
import TaskDimensionsTable from './TaskDimensionsTable';
import IDPGDataTable from "./IDPGDataTable";
import PersonalityCodeTable from "./PersonalityCodeTable";
import PresonalityChartTable from "./PresonalityChartTable";
import PeopleDimensionsTable from "./PeopleDimensionsTable";
const AssessmentReport = ({ onCloseCallback, personalityCodeMaster, motivationCodeCodeMaster, aptitudeCodeMaster, isReportingManager = false }) => {
    const handleOnClickCallBack = () => {
        onCloseCallback()
    }
    const renderFullName = (key) => {
        let temp;
        let list = personalityCodeMaster && personalityCodeMaster.results;
        let value = key && key.toLowerCase();
        list.map((item) => {
            let shortName = item.shortName && item.shortName.toLowerCase();
            if (shortName == value) {
                temp = item.subCategory
            }
        })
        return temp;
    }
    const renderValue = (key) => {
        let temp;
        let list = personalityCodeMaster && personalityCodeMaster.results;
        let value = key && key.toLowerCase();
        list.map((item) => {
            let shortName = item.shortName && item.shortName.toLowerCase();
            if (shortName == value) {
                temp = item.totalWeightage
            }
        })
        return temp;
    }

    const renderDiffValue = (maxKey, minKey) => {
        let list = personalityCodeMaster && personalityCodeMaster.results;
        let tempMaxKey = maxKey && maxKey.toLowerCase();
        let tempMinKey = minKey && minKey.toLowerCase();
        let a = 0, b = 0;
        list.map((item) => {
            let shortName = item.shortName && item.shortName.toLowerCase();
            if (shortName == tempMaxKey) {
                a = item.totalWeightage;
            }
            if (shortName == tempMinKey) {
                b = item.totalWeightage;
            }
        })
        return a - b
    }
    const ploarChartData = () => {
        let tempData = [];
        let tempLabel = []
        let list = motivationCodeCodeMaster && motivationCodeCodeMaster.results;
        list.map((item) => {
            tempData.push(item.totalWeightage);
            tempLabel.push(item.shortName + " - " + item.shortName)
        })

        const data = {
            datasets: [
                {
                    data: [...tempData],
                    label: motivationCodeCodeMaster?.categoryName || ''
                }
            ],
            labels: tempLabel
        };
        return data
    }
    const calculateIQ = () => {
        let list = aptitudeCodeMaster && aptitudeCodeMaster.results;
        let totalWeightageCount = 0;
        list.map((item) => {
            totalWeightageCount += parseInt(item.totalWeightage)
        })
        return totalWeightageCount;
    }
    const bindPresonalityValue = (start, end, position, type = "asc") => {
        let returnValue = "";
        if (type === "desc") {
            for (let i = end; i >= start; i--) {
                const showValue = i * 5;
                const lastValue = ((i - 1) * 5)
                if (showValue === position) {
                    returnValue += "X "
                } else {
                    returnValue += showValue + " "
                    if (position > lastValue && showValue > position) {
                        returnValue += "X "
                    }

                }

            }
        } else {
            for (let i = start; i <= end; i++) {
                const showValue = i * 5;
                const lastValue = ((i + 1) * 5)
                if (showValue === position) {
                    returnValue += "X "
                } else {
                    returnValue += showValue + " "
                    if (lastValue > position && showValue < position) {
                        returnValue += "X "
                    }

                }
            }
        }
        return returnValue;
    }
    return (
        <>
            <DialogWrapper className={'width_900'}>
                <div className='modal-header'>
                    <h5 className="modal-title">Assessment Report</h5>
                    <div className='dialog-close'>
                        <img src={__getImage('/dialog_close.svg')} onClick={(e) => { handleOnClickCallBack(null) }} />
                    </div>
                </div>
                <div className='modal-body'>
                    {personalityCodeMaster ? <>
                        <div>
                            {!isReportingManager ? <>
                                {"IQ Score : " + calculateIQ()}

                                <br /><br />
                            </> : ''}
                            <PersonalityCodeTable personalityCodeMaster={personalityCodeMaster} renderValue={renderValue} renderDiffValue={renderDiffValue} />
                            {!isReportingManager ? <>
                                <PresonalityChartTable renderValue={renderValue} renderFullName={renderFullName} bindPresonalityValue={bindPresonalityValue} />
                            </> : ''}
                            <br />
                            <br />
                            <PeopleDimensionsTable renderValue={renderValue} renderFullName={renderFullName} />
                            <br />
                            <TaskDimensionsTable renderValue={renderValue} renderFullName={renderFullName} />
                            <br /><br />
                            {!isReportingManager ? <IDPGDataTable renderValue={renderValue} renderFullName={renderFullName} /> : ''}
                        </div>
                    </> : ''}
                    {motivationCodeCodeMaster & !isReportingManager ? <>
                        <hr />
                        {motivationCodeCodeMaster?.categoryName}
                        <br /><br />
                        <PolarAreaChart data={ploarChartData()} />
                    </> : ''}
                </div>
            </DialogWrapper>
        </>
    )
}
export default AssessmentReport