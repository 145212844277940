import EmployerMenuData from "./EmployerMenuData";
import UniversityMenuData from "./UniversityMenuData";
import SuperAdminMenuData from "./SuperAdminMenuData";
import AdminMenuData from "./AdminMenuData";
export const NavData = {
    employer: [
        ...EmployerMenuData
    ],
    university: [
        ...UniversityMenuData
    ],
    superadmin: [
        ...SuperAdminMenuData
    ],
    admin: [
        ...AdminMenuData
    ]
}