import React from 'react';
import { __httpEnrolmentList } from '../../Enrolments/enrolmentService';
import Card from '../../../Components/Card';
import { __getImage, __getLoginDetail, __startEndIndex } from '../../../_factory/globalService';
import style from './listinig.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { __httpDeleteQuestion, __httpQuestionList } from '../QuestionService';
import StatusDialog from '../../../Components/Dialog/StatusDialog';
import ConfirmDialog from '../../../Components/Dialog/ConfirmDialog';
import { _QuestionAction } from '../../../_store/Question/question.action';
import { connect } from 'react-redux';
import Paging from '../../../Components/Paging';
import { Dropdown } from 'primereact/dropdown';
import { __getMasterList } from '../../../_factory/globalMasterService';
const QuestionListComponent = ({ ...props }) => {
    const params = useParams();
    let navigate = useNavigate();
    const [filterParams, set_filterParams] = React.useState({ categoryId: '', subCategory: '', carrierId: '' });
    const [listData, set_listData] = React.useState(null);
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [statusDialogConfig, set_statusDialogConfig] = React.useState(null);
    const [confirmDialogConfig, set_confirmDialogConfig] = React.useState(null);
    const [selectedId, set_selectedId] = React.useState(null);
    const [activePage, set_activePage] = React.useState(0);
    const [perPage] = React.useState(10);
    const [startIndex, set_startIndex] = React.useState(0);
    const [maxIndex, set_maxIndex] = React.useState(0);
    const [filteredListData, set_filteredListData] = React.useState(null);
    const [industryMaster, set_industryMaster] = React.useState([])
    const [categoryMaster, set_categoryMaster] = React.useState([]);
    const [subCategoryMaster, set_subCategoryMaster] = React.useState([]);
    React.useEffect(() => {
        gerCarrierMaster()
        getCategoryMaster('category');
        getListingData();
    }, [])
    React.useEffect(() => {
        const startEndIndex = __startEndIndex(activePage, perPage);
        set_startIndex(startEndIndex?.startIndex);
        set_maxIndex(startEndIndex?.endIndex)
    }, [activePage]);
    React.useEffect(() => {
        filterListingData(filterParams)
    }, [listData]);

    /* get master lists */
    const gerCarrierMaster = async () => {
        let body = {
            "type": 'career'
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_industryMaster(responseData)
    };
    const getCategoryMaster = async () => {
        let body = {
            "type": 'category'
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_categoryMaster(responseData)
    }
    /* get listing data */
    const getListingData = () => {
        const loader = document.getElementById("loader2");
        if (loader) {
            loader.style.display = "block";
        }
        __httpQuestionList().then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                set_listData(res.data.responseData)
            } else {
                set_listData(null)
            }
            set_isDataLoaded(true)
        }).finally(() => {
            const loader = document.getElementById("loader2");
            if (loader) {
                loader.style.display = "none";
            }
        })
    }
    const filterListingData = (filterObj = {}) => {
        let filteredData = [];
        if (listData && listData.length > 0) {
            let categoryId = filterObj && filterObj.categoryId || '';
            let subCategory = filterObj && filterObj.subCategory && filterObj.subCategory.toLowerCase() || '';
            let carrierId = filterObj && filterObj.carrierId || '';
            if (!categoryId && !subCategory && !carrierId) {
                filteredData = listData || [];
            } else {
                filteredData = (listData || []).filter((item) => {
                    const temp_categoryId = item && item.categoryId || '';
                    const temp_subCategory = item && item.subCategory && item.subCategory.toLowerCase() || '';
                    const temp_carrierId = item && item.carrierId || '';
                    if ((temp_categoryId && temp_categoryId.includes(categoryId)) && (temp_carrierId && temp_carrierId.includes(carrierId)) && (temp_subCategory && temp_subCategory.includes(subCategory))) {
                        return true
                    }
                });
            }
        } else {
            filteredData = listData || []
        }

        set_filteredListData(filteredData)
    }
    const handleQuestionForm = (obj, type) => {
        let config = {}
        let url;
        if (obj) {
            obj.id = obj.id;
            config = {
                ...obj
            }
            url = `/question/form/${obj.id}`
        } else {
            url = `/question/form/0`
        }
        config.type = type;
        props.dispatch(_QuestionAction({ ...config }));
        navigate(url);
    }
    const handle_onYesClickCallBack = () => {
        let id = selectedId;
        let postDto = {
            "id": id,
            "categoryId": "-1"
        }
        __httpDeleteQuestion(postDto).then(res => {
            set_confirmDialogConfig(null)
            let config = {}
            if (res && res.data) {
                if (res.data.isSuccess) {
                    config = {
                        body: res.data.responseMessage || 'Record Deleted successfully',
                        isSuccess: true,
                        isFooter: false
                    }
                    set_statusDialogConfig(config)
                    setTimeout(() => {
                        set_statusDialogConfig(null);
                        getListingData()
                    }, 1000)
                } else {
                    config = {
                        body: res.data.responseMessage || 'Something went wrong',
                        isSuccess: false,
                        isFooter: true
                    }
                    set_statusDialogConfig(config)
                }
                set_selectedId(null)
            } else {
                config = {
                    body: 'Something went wrong',
                    isSuccess: false,
                    isFooter: true
                }
                set_statusDialogConfig(config)
            }
        })
    }
    const handle_onNoClickCallBack = () => {
        set_confirmDialogConfig(null)
    }
    const handleDeleteRecord = (id) => {
        let cDC = {
            isOpen: true,
            title: 'Message',
            body: 'Are you sure you want to delete ?'
        }
        set_selectedId(id)
        set_confirmDialogConfig(cDC)
    }
    const handleOnClickCallBack = () => {
        set_statusDialogConfig(null)
    }
    const handleOnPaginationCallback = (pageNumber) => {
        set_activePage(pageNumber)
    }
    const createSubCategoryMaster = (id) => {
        var index = categoryMaster.map((category) => { return category.id; }).indexOf(id);
        if (index >= 0) {
            if (categoryMaster[index]) {
                let temp = categoryMaster[index] && categoryMaster[index]['subItems'];
                set_subCategoryMaster(temp || [])
            }
        }
    }
    const handleFilter = (value, key) => {
        if (key == 'categoryId') {
            set_subCategoryMaster([]);
            if (value) {
                createSubCategoryMaster(value)
            }
        }
        let temp = {
            ...filterParams,
            [key]: value
        }
        set_filterParams(temp);
        filterListingData(temp);
    }
    const handleClearFilter = () => {
        let temp = { categoryId: '', subCategory: '', carrierId: '' }
        set_filterParams(temp);
        filterListingData(temp);
    }
    return (<>
        <Card>
            <div className={style['enrollment_wrapper']}>
                <div className={'plr-25'}>
                    <div className='row'>
                        <div className='col-sm-12 col-md-9'>
                            <div className='row'>
                                <div className='col-md-4 col-sm-12'>
                                    <div className='form-group'>
                                        <Dropdown value={filterParams.categoryId} onChange={(e) => handleFilter(e.value, 'categoryId')} options={categoryMaster} optionLabel="name" optionValue='id' placeholder="Select Category" className="form-control" />
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-12'>
                                    <div className='form-group'>
                                        <Dropdown value={filterParams.subCategory} onChange={(e) => handleFilter(e.value, 'subCategory')} options={subCategoryMaster} optionLabel="name" optionValue='name' placeholder="Select Sub Category" className="form-control" />
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-12'>
                                    <div className='form-group'>
                                        <Dropdown value={filterParams.carrierId} onChange={(e) => handleFilter(e.value, 'carrierId')} options={industryMaster} optionLabel="name" optionValue='id' placeholder="Select Industry" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-3'>
                            <div className=''>
                                <div className='d-flex justify-content-end width_100'>
                                    {/* <button type='button' className='btn custom_btn btn_next no-wrap'>
                                        <img src={__getImage('filtersearch.svg')} />
                                        Filter
                                    </button> */}
                                    <button type='button' className={`ml-20 btn custom_btn btn_transparent no-wrap`} onClick={() => { handleClearFilter() }}>
                                        <img src={__getImage('filtersearch.svg')} />
                                        &nbsp;
                                        Clear Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className='page_header'>
                    <div>
                        <div className='page_title'>
                            Questions
                        </div>
                        {isDataLoaded && filteredListData && <div className='count'>{filteredListData.length} Record{filteredListData.length > 9 ? 's' : ''}</div>}
                    </div>
                    <div className='d-flex'>
                        <button type='button' className={`ml-10 btn_primary`} onClick={() => { handleQuestionForm(null, 0) }}>
                            <img src={__getImage('exportsquare.svg')} />
                            New
                        </button>
                    </div>
                </div>
                <div className='table_wrapper scrollBar'>
                    <table id="saleReportTable">
                        <thead>
                            <tr>
                                <th>
                                    <div className='content'>
                                        Question
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Category
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Sub Catgory
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Time Limit
                                    </div>
                                </th>
                                <th>
                                    <div className='content text-center'>
                                        Action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isDataLoaded ? <tr>
                                <td colSpan={100}>
                                    <div className='content'>

                                    </div>
                                </td>
                            </tr> : <>
                                {filteredListData && filteredListData.length > 0 ? <>
                                    {filteredListData.slice(startIndex, maxIndex).map((ele, i) => {
                                        return (
                                            <tr key={`en_${i}`}>
                                                <td>
                                                    <div className={`content ${style['ques_cell']}`}>
                                                        <div dangerouslySetInnerHTML={{ __html: ele?.question || 'NA' }} ></div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.category || 'NA'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.subCategory || 'NA'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.timelimit || 'NA'}
                                                    </div>
                                                </td>
                                                <td className='text-center'>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='mr-10 pointer content' onClick={() => { handleQuestionForm(ele, 2) }}>
                                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                                        </div>
                                                        <div className='mr-10 pointer content' onClick={() => { handleQuestionForm(ele, 1) }}>
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </div>
                                                        <div className='pointer content' onClick={() => { handleDeleteRecord(ele?.id) }}>
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </> : <>
                                    <tr>
                                        <td colSpan={100}>
                                            <div className='content'>
                                                No records...
                                            </div>
                                        </td>
                                    </tr>
                                </>}
                            </>}
                        </tbody>
                    </table>
                </div>
                {isDataLoaded && filteredListData && filteredListData.length > 0 ? <div className='pagers'>
                    <div></div>
                    <Paging total={filteredListData.length} perPage={perPage} activePage={activePage} onPaginationCallback={handleOnPaginationCallback} />
                </div> : ''}
            </div>
        </Card>
        {statusDialogConfig && <StatusDialog body={statusDialogConfig.body} isTitle={true} isSuccess={statusDialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={statusDialogConfig?.isFooter} />}
        {confirmDialogConfig && confirmDialogConfig.isOpen ? <ConfirmDialog body={confirmDialogConfig?.body} isTitle={true} title={confirmDialogConfig?.title} onYesClickCallBack={handle_onYesClickCallBack} onNoClickCallBack={handle_onNoClickCallBack} /> : ''}
    </>)
}
export default connect(null, null)(QuestionListComponent);