import React from 'react';
const PresonalityChartTable = ({ renderValue, renderFullName, bindPresonalityValue }) => {
    return (
        <>
            {"Presonality Chart"}
            <br /><br />
            <div className='table_wrapper auto'>
                <table>
                    <thead>
                        <tr>
                            <th>  SO {renderFullName("SO")}</th>
                            <th style={{ 'padding': '14px 10px' }}> {bindPresonalityValue(2, 10, renderValue('SO') > renderValue('G') ? renderValue('SO') : 0, "desc")}

                            </th>

                            <th className="text-right" style={{ 'padding': '14px 10px' }}>{bindPresonalityValue(2, 10, renderValue('SO') <= renderValue('G') ? renderValue('G') : 0)} &nbsp;</th>
                            <th className="text-right">  G {renderFullName("G")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>  A {renderFullName("A")}</th>
                            <th style={{ 'padding': '14px 10px' }}> {bindPresonalityValue(2, 10, (renderValue('A') > renderValue('P') ? renderValue('A') : 0), "desc")}
                            </th>
                            <th className="text-right" style={{ 'padding': '14px 10px' }}>{bindPresonalityValue(2, 10, (renderValue('A') <= renderValue('P') ? renderValue('P') : 0))} </th>
                            <th className="text-right">  P {renderFullName("P")}</th>
                        </tr>

                        <tr>
                            <th>  I {renderFullName("I")}</th>
                            <th style={{ 'padding': '14px 10px' }}> {bindPresonalityValue(2, 10, (renderValue('I') > renderValue('F') ? renderValue('I') : 0), "desc")}
                            </th>
                            <th className="text-right" style={{ 'padding': '14px 10px' }}>{bindPresonalityValue(2, 10, (renderValue('I') <= renderValue('F') ? renderValue('F') : 0))} </th>
                            <th className="text-right">  F {renderFullName("F")}</th>
                        </tr>
                        <tr>
                            <th>  SP {renderFullName("S")}</th>
                            <th style={{ 'padding': '14px 10px' }}> {bindPresonalityValue(2, 10, (renderValue('S') > renderValue('D') ? renderValue('S') : 0), "desc")}
                            </th>
                            <th className="text-right" style={{ 'padding': '14px 10px' }}>{bindPresonalityValue(2, 10, (renderValue('S') <= renderValue('D') ? renderValue('D') : 0))} </th>
                            <th className="text-right">  D {renderFullName("D")}</th>
                        </tr>

                    </tbody>
                </table>
            </div>
            <br />
        </>
    )
}
export default PresonalityChartTable;