const UniversityMenuData = [
    {
        ds_nm: 'Dashboard',
        url: '/dashboard/university',
        icon:'fa fa-tachometer',
        activeMenuFor: ['/dashboard/university']
    },
    {
        ds_nm: 'Student Enrolments',
        url: '/enrolment/list/university',
        icon:'fa fa-users',
        activeMenuFor: ['/enrolment/list/university']
    },
    // {
    //     ds_nm: 'Assessment',
    //     url: '',
    //     icon:'fa fa-book',
    //     activeMenuFor: ['']
    // }
]
export default UniversityMenuData;