export const accountType = [
    {
        name: 'Super Admin',
        value: 'superadmin'
    },
    {
        name: 'Admin',
        value: 'admin'
    }, {
        name: 'University',
        value: 'university'
    },
    {
        name: 'Employer',
        value: 'employer'
    }
]

export const answerType = [
    {
        name: 'Horizontal',
        value: 'horizontal'
    },
    {
        name: 'Vertical',
        value: 'vertical'
    }
]