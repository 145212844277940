import React from "react";
const PeopleDimensionsTable = ({ renderValue, renderFullName }) => {
    return (
        <div className='table_wrapper auto'>
            <table>
                <thead>
                    <tr>
                        <th >  {"People dimensions"}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td >
                            {renderValue('SO') > renderValue('G') ? renderFullName('SO') : renderFullName('G')}
                        </td>
                    </tr>
                    <tr>
                        <td >
                            {renderValue('A') > renderValue('P') ? renderFullName('A') : renderFullName('P')}
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default PeopleDimensionsTable;