import React from "react";
import { InputText } from "primereact/inputtext";
const InputNumber = ({
  input,
  placeholder,
  inputOnChange,
  disabledinput,
  maxlength,
  minlength,
  value,
  minValue,
  maxValue,
  label,
  meta: { touched, error },
}) => {
  const inputProps = {
    ...input,
    onChange: (e) => {
      let value = e.target.value.replace(/[^0-9\.]/g, '');
      if (value && value.split('.').length > 2) {
        value = value.replace(/\.+$/, "");
      }
      if (e?.target?.min && parseInt(value) < parseInt(e?.target?.min)) {
        value = parseInt(e?.target?.min)
      } else if (e?.target?.max && parseInt(value) > parseInt(e?.target?.max)) {
        value = parseInt(e?.target?.max)
      }
      e.target.value = value;
      input.onChange(e);
    },
  };
  return (
    <>
      <span className="p-float-label width_100">
        <InputText
          {...inputProps}
          placeholder={placeholder}
          type={input.type}
          min={minValue}
          max={maxValue}
          maxLength={maxlength}
          minLength={minlength}
          readOnly={disabledinput ? true : false}
          className={`form-control form-control-sm ${touched && error ? " is-invalid focus-visible" : ""
            }`}
        />
        <label>{label || placeholder}</label>
      </span>

      {touched && error && (
        <span className="error error-feedback">{error}</span>
      )}
    </>
  );
};
export default InputNumber;
