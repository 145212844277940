import React from 'react';
import { Route, Routes } from "react-router-dom";
import PrivateHook from './PrivateHook';
import LoginWithType from '../Pages/Auth/login';
import RegisterWithType from '../Pages/Auth/register';
import Dashboard from '../Pages/DashBoard';
import EnrolmentListing from '../Pages/Enrolments/EnrolmentListing';
import EnrolmentDetail from '../Pages/Enrolments/Detail';
import LayoutWithSideNav from './LayoutWithSideNav';
import JobListingComponent from '../Pages/Job/JobListingComponent';
import { routerConfig } from '../_factory/routerConfig';
import JobPostForm from '../Pages/Job/JobPostForm/JobPostForm';
import JobPostView from '../Pages/Job/JobPostForm/JobPostView';
import AppliedJobListComponent from '../Pages/Job/AppliedJobListComponent';
import MasterSkillComponent from '../Pages/Masters/MasterSkillComponent';
import AccountManagementComponent from '../Pages/Account/AccountManagement/AccountListing';
import QuestinFormWrapper from '../Pages/Questions/QuestinFormWrapper';
import QuestionListComponent from '../Pages/Questions/QuestionListComponent';
import ReportingManagerListComponent from '../Pages/ReportingManager/list';
import EnrolmentReportDetail from '../Pages/Enrolments/Detail/viewReport';
const Navigation = ({ ...props }) => {
    return (
        <>
            <Routes>
                {/* Start */}
                <Route path={'/'} element={<LoginWithType />} />

                {/* auth */}
                <Route path={routerConfig.login.route} element={<PrivateHook><LoginWithType /></PrivateHook>} />
                <Route path={routerConfig.register.route} element={<PrivateHook><RegisterWithType /></PrivateHook>} />

                {/* Enrolments */}
                <Route path={routerConfig?.enrolment?.list?.route} element={<LayoutWithSideNav isSideNav={true} isByPassLogin={true}><EnrolmentListing /></LayoutWithSideNav>} />
                <Route path={routerConfig?.enrolment?.view?.route} element={<LayoutWithSideNav isSideNav={true} isByPassLogin={true}><EnrolmentDetail /></LayoutWithSideNav>} />
                <Route path={routerConfig?.enrolment?.report?.route} element={<LayoutWithSideNav isSideNav={true} isByPassLogin={true}><EnrolmentReportDetail /></LayoutWithSideNav>} />

                {/* Dashboard */}
                <Route path={routerConfig?.dashboard?.route} element={<LayoutWithSideNav isSideNav={true} authGuard={false} isByPassLogin={true}><Dashboard /></LayoutWithSideNav>} />

                {/* Jobs */}
                <Route path='/job/listing' element={<LayoutWithSideNav isSideNav={true} isByPassLogin={true}><JobListingComponent /></LayoutWithSideNav>} />
                <Route path='/job/post/form/:jobId' element={<LayoutWithSideNav isSideNav={false} isByPassLogin={true}><JobPostForm /></LayoutWithSideNav>} />
                <Route path='/job/post/view/:jobId' element={<LayoutWithSideNav isSideNav={false} isByPassLogin={true}><JobPostView /></LayoutWithSideNav>} />
                <Route path='/job/listing/applied' element={<LayoutWithSideNav isSideNav={true} isByPassLogin={true}><AppliedJobListComponent /></LayoutWithSideNav>} />

                {/* Master */}
                <Route path='/master/:masterType' element={<LayoutWithSideNav isSideNav={true} isByPassLogin={true}><MasterSkillComponent /></LayoutWithSideNav>} />

                {/* Account */}
                <Route path='/account/management' element={<LayoutWithSideNav isSideNav={true} isByPassLogin={true}><AccountManagementComponent /></LayoutWithSideNav>} />

                {/* Question */}
                <Route path='/question/listing' element={<LayoutWithSideNav isSideNav={true} isByPassLogin={true}><QuestionListComponent /></LayoutWithSideNav>} />
                <Route path='/question/form/:id' element={<LayoutWithSideNav isSideNav={true} isByPassLogin={true}><QuestinFormWrapper /></LayoutWithSideNav>} />

                {/* Reporting Manager */}
                <Route path='/reporting-manager/list' element={<LayoutWithSideNav isSideNav={true} isByPassLogin={true}><ReportingManagerListComponent /></LayoutWithSideNav>} />
            </Routes>
        </>
    )
}
export default Navigation;