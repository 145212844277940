import React from 'react';
import { __httpEnrolmentList } from '../../Enrolments/enrolmentService';
import Card from '../../../Components/Card';
import { __getImage, __getLoginDetail, __startEndIndex } from '../../../_factory/globalService';
import style from './listinig.module.scss';
import { __getMasterList } from '../../../_factory/globalMasterService';
import AddMasterFormComponent from '../AddMasterForm';
import { useParams } from 'react-router-dom';
import Paging from '../../../Components/Paging';
import ConfirmDialog from '../../../Components/Dialog/ConfirmDialog';
import { __saveMasterList } from '../masterService';
import StatusDialog from '../../../Components/Dialog/StatusDialog';
const MasterSkillComponent = () => {
    const params = useParams();
    const [listData, set_listData] = React.useState(null);
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [masterDialogConfig, set_masterDialogConfig] = React.useState(null);
    const [activePage, set_activePage] = React.useState(0);
    const [perPage] = React.useState(10);
    const [startIndex, set_startIndex] = React.useState(0);
    const [maxIndex, set_maxIndex] = React.useState(0);
    const [selectedId, set_selectedId] = React.useState(null);
    const [confirmDialogConfig, set_confirmDialogConfig] = React.useState(null);
    const [statusDialogConfig, set_statusDialogConfig] = React.useState(null);
    const [searchParam, set_searchParam] = React.useState(
        {
            "type": params?.masterType
        }
    );
    React.useEffect(() => {
        let temp = {
            "type": params?.masterType
        }
        set_searchParam(temp)
        getListingData(temp);
    }, [params])


    React.useEffect(() => {
        const startEndIndex = __startEndIndex(activePage, perPage);
        set_startIndex(startEndIndex?.startIndex);
        set_maxIndex(startEndIndex?.endIndex)
    }, [activePage])


    const getListingData = (searchParam) => {
        __getMasterList(searchParam).then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                set_listData(res.data.responseData)
            } else {
                set_listData(null)
            }
            set_isDataLoaded(true)
        })
    }
    const openForm = (value) => {
        let formValue = {};
        let title = `Add ${params?.masterType}`
        if (value) {
            title = `Edit ${params?.masterType}`
            formValue = {
                "id": value?.id,
                "name": value?.name,
                "type": value?.type
            }
        } else {
            formValue = {
                "id": "",
                "name": "",
                "type": params?.masterType
            }
        }
        let temp = {
            isOpen: true,
            title: title,
            formValue: formValue
        }
        set_masterDialogConfig(temp)
    }
    const closeMasterFormDialog = (e) => {
        if (e && e.isReload) {
            getListingData(searchParam);
        }
        set_masterDialogConfig({})
    }
    const handleOnPaginationCallback = (pageNumber) => {
        set_activePage(pageNumber)
    }
    const handleDeleteRecord = (id) => {
        let cDC = {
            isOpen: true,
            title: 'Message',
            body: 'Are you sure you want to delete ?'
        }
        set_selectedId(id)
        set_confirmDialogConfig(cDC)
    }
    const handle_onNoClickCallBack = () => {
        set_confirmDialogConfig(null)
    }
    const handle_onYesClickCallBack = () => {
        let id = selectedId;
        let postDto = {
            "id": id,
            "type": "-1"
        }
        __saveMasterList(postDto).then(res => {
            set_confirmDialogConfig(null)
            let config = {}
            if (res && res.data) {
                if (res.data.isSuccess) {
                    config = {
                        body: res.data.responseMessage || 'User Data saved successfully',
                        isSuccess: true,
                        isFooter: false
                    }
                    set_statusDialogConfig(config);
                    getListingData(searchParam)
                    setTimeout(() => {
                        set_statusDialogConfig(null);
                    }, 2000)
                } else {
                    config = {
                        body: res.data.responseMessage || 'Something went wrong',
                        isSuccess: false,
                        isFooter: true
                    }
                    set_statusDialogConfig(config)
                }
            } else {
                config = {
                    body: 'Something went wrong',
                    isSuccess: false,
                    isFooter: true
                }
                set_statusDialogConfig(config)
            }
            set_selectedId(null)
        })
    }
    const handleOnClickCallBack = () => {
        set_statusDialogConfig(null)
    }
    return (<>
        <Card>
            <div className={style['enrollment_wrapper']}>
                <div className={style['page_header']}>
                    <div>
                        <div className={style['page_title']}>
                            List of {params?.masterType}
                        </div>
                        {isDataLoaded && listData && <div className={style['count']}>{listData.length} Record{listData.length > 9 ? 's' : ''}</div>}
                    </div>
                    <div className='d-flex'>
                        <button type='button' className={`ml-10 ${style['btn_primary']}`} onClick={() => { openForm(null) }}>
                            <img src={__getImage('exportsquare.svg')} />
                            New
                        </button>
                    </div>
                </div>
                <div className='table_wrapper scrollBar'>
                    <table id="saleReportTable">
                        <thead>
                            <tr>
                                <th>
                                    <div className='content no-wrap'>
                                        Si. No.
                                    </div>
                                </th>
                                <th className='width_100'>
                                    <div className='content'>
                                        Name
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isDataLoaded ? <tr>
                                <td colSpan={100}>
                                    <div className='content'>

                                    </div>
                                </td>
                            </tr> : <>
                                {listData && listData.length > 0 ? <>
                                    {listData && listData.slice(startIndex, maxIndex).map((ele, i) => {
                                        return (
                                            <tr key={`en_${i}`}>
                                                <td style={{ 'width': '100px' }}>
                                                    {i + 1}
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.name}
                                                    </div>
                                                </td>
                                                <td className='text-center'>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='mr-10 pointer content' onClick={() => { openForm(ele) }}>
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </div>
                                                        <div className='pointer content' onClick={() => { handleDeleteRecord(ele?.id) }}>
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>
                                        )
                                    })}
                                </> : <>
                                    <tr>
                                        <td colSpan={100}>
                                            <div className='content'>
                                                No records...
                                            </div>
                                        </td>
                                    </tr>
                                </>}
                            </>}
                        </tbody>
                    </table>
                </div>
                {isDataLoaded && listData && listData.length > 0 ? <div className='pagers'>
                    <div></div>
                    <Paging total={listData.length} perPage={perPage} activePage={activePage} onPaginationCallback={handleOnPaginationCallback} />
                </div> : ''}
            </div>
        </Card>
        {masterDialogConfig && masterDialogConfig.isOpen && <AddMasterFormComponent data={masterDialogConfig} onCloseCallback={closeMasterFormDialog}></AddMasterFormComponent>}
        {confirmDialogConfig && confirmDialogConfig.isOpen ? <ConfirmDialog body={confirmDialogConfig?.body} isTitle={true} title={confirmDialogConfig?.title} onYesClickCallBack={handle_onYesClickCallBack} onNoClickCallBack={handle_onNoClickCallBack} /> : ''}
        {statusDialogConfig && <StatusDialog body={statusDialogConfig.body} isTitle={true} isSuccess={statusDialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={statusDialogConfig.isFooter} />}

    </>)
}
export default MasterSkillComponent;