import React from "react";
const RMViewDetailComponent = ({ formValue = {} }) => {
    return (
        <div className='form_wrapper'>
            <div className='row row-same-height'>
                <div className='col-same-height col-sm-12 col-md-6'>
                    <div className='content form-group'>
                        <label>Name</label>
                        <div className='form-control view'>
                            {formValue?.name}
                        </div>
                    </div>
                </div>
                <div className='col-same-height col-sm-12 col-md-6'>
                    <div className='content form-group'>
                        <label>Email</label>
                        <div className='form-control view'>
                            {formValue?.email}
                        </div>
                    </div>
                </div>
                <div className='col-same-height col-sm-12 col-md-6'>
                    <div className='content form-group'>
                        <label>Mobile Number</label>
                        <div className='form-control view'>
                            {formValue?.mobileNumber}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RMViewDetailComponent;