import React from 'react';
import qs from './question.module.scss';
import { __getImage } from '../../_factory/globalService';
import { __getMasterList } from '../../_factory/globalMasterService';
import { __httpSaveQuestion } from './QuestionService';
import { connect } from 'react-redux';
import QuestinFormAddWrapper from './QuestinFormAddWrapper';
import QuestinDetailWrapper from './QuestinDetailWrapper';
let initialForm = {
    "id": "",
    "categoryId": "",
    "category": "",
    "subCategory": "",
    "carrierId": "",
    "carrier": "",
    "timelimit": "",
    "answerType": '',
    "question": "",
    "answers": [
        {
            "answer": "",
            "weightage": "",
            "tag": ""
        }
    ]
}
const QuestinFormWrapper = ({ ...props }) => {
    const { _QuestionDetailReducer = null } = props;
    const [categoryMaster, set_categoryMaster] = React.useState([]);
    const [carrierMaster, set_carrierMaster] = React.useState([]);
    React.useEffect(() => {
        get_category('category');
        get_carrier('career');
    }, []);


    const get_category = async (type) => {
        let body = {
            "type": type
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_categoryMaster(responseData)
    }

    const get_carrier = async (type) => {
        let body = {
            "type": type
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_carrierMaster(responseData)
    }

    return (
        <>
            <div className='page_wrapper'>
                <div className='container'>
                    <div className={qs['question_inner']}>
                        <h1 className={qs['title']}>Question Management</h1>
                        {_QuestionDetailReducer ? <>
                            {_QuestionDetailReducer?.type == 2 ? <QuestinDetailWrapper carrierMaster={carrierMaster} categoryMaster={categoryMaster || []} qs={qs} _QuestionDetailReducer={_QuestionDetailReducer} initialForm={initialForm} /> : <QuestinFormAddWrapper carrierMaster={carrierMaster} categoryMaster={categoryMaster || []} qs={qs} _QuestionDetailReducer={_QuestionDetailReducer} initialForm={initialForm} />}
                        </> : ''}
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    const { _QuestionDetailReducer } = state.question;
    return {
        _QuestionDetailReducer,
    }
}
export default connect(mapStateToProps, null)(QuestinFormWrapper);