import React from 'react';
import style from './index.module.scss';
import CustomButton from '../../../Components/form/CustomButton';
import CarrierMatchTpl from './CarrierMatchTpl';
import AssessmentDetail from './AssessmentDetail';
import { __httpAssignmentMaster, __httpEnrolmentDetail, __httpStudentAssignment, __httpStudentAssignmentDetail } from '../enrolmentService';
import { __getImage, __getImageurl, __getLoginDetail, __localStorageGet } from '../../../_factory/globalService';
import { ProgressBar } from 'primereact/progressbar';
import { Carousel } from 'primereact/carousel';
import { useNavigate, useParams } from 'react-router-dom';
import AssessmentReport from './Report';
import EducationTpl from './EducationTpl';
//import CustomCircularProgressBar from '../../../Components/Chart/CustomCircularProgressBar';
const EnrolmentDetail = ({ ...props }) => {
    const params = useParams();
    let navigate = useNavigate();
    const [pCompletion, set_pCompletion] = React.useState(0);
    const [detail, set_detail] = React.useState(null);
    //const [assessment, set_assessment] = React.useState(null);
    const [masterDialogConfig, set_masterDialogConfig] = React.useState(null);
    const [reportDialogConfig, set_reportDialogConfig] = React.useState(null);
    const [aptitudeCodeMaster, set_aptitudeCodeMaster] = React.useState(null);
    const [personalityCodeMaster, set_personalityCodeMaster] = React.useState(null);
    const [motivationCodeCodeMaster, set_motivationCodeCodeMaster] = React.useState(null);

    const userData = __localStorageGet('userDataTM');
    const loginType = userData && userData.type;


    //const [usrInfo, set_usrInfo] = React.useState(null);
    React.useEffect(() => {
        //let userData = __getLoginDetail();
        //set_usrInfo(userData)
        if (params && params.studentId.length > 1) {
            getDetail(params.studentId);
            // getAssessmentMaster()
        }
    }, []);
    const calculateProfileCalculatoin = (responseData) => {
        let temp = 0;
        if (responseData) {
            if (responseData.firstName) {
                temp = temp + 10;
            }
            if (responseData.lastName) {
                temp = temp + 10;
            }
            if (responseData.workExperiances && responseData.workExperiances.length > 0) {
                temp = temp + 20;
            }
            if (responseData.educations && responseData.educations.length > 0) {
                temp = temp + 20;
            }
            if (responseData.skills && responseData.skills.length > 0) {
                temp = temp + 20;
            }
            if (responseData.profilePic) {
                temp = temp + 10;
            }
            if (responseData.resumes) {
                temp = temp + 10;
            }
            setTimeout(() => {
                set_pCompletion(temp)
            }, 300)

        }
    }
    const getDetail = (id) => {
        __httpEnrolmentDetail(id).then(res => {
            const responseData = res.data.responseData;
            getAssessmentDetail(params.studentId)
            calculateProfileCalculatoin(responseData)
            // if (responseData && responseData.profileStatus >= 2) {
            //     getStudentAssessment(params.studentId)
            // }
            set_detail(responseData)
        })
    }
    // const getAssessmentMaster = () => {
    //     __httpAssignmentMaster().then(res => {
    //         const responseData = res && res.data && res.data.responseData;
    //         if (responseData) {
    //             // createCustomData(responseData)
    //         }
    //     })
    // }
    const getAssessmentDetail = (id) => {
        __httpStudentAssignmentDetail(id).then(res => {
            let temp_aptitudeCodeMaster = null;
            let temp_personalityCodeMaster = null;
            let temp_motivationCodeCodeMaster = null;
            const responseData = res && res.data && res.data.responseData;
            if (responseData) {
                responseData.map((item, index) => {
                    if (item) {
                        if (item.categoryName == 'Aptitude Code') {
                            temp_aptitudeCodeMaster = item
                        }
                        if (item.categoryName == 'Personality Code') {
                            temp_personalityCodeMaster = item
                        }
                        if (item.categoryName == 'Motivation Code') {
                            temp_motivationCodeCodeMaster = item
                        }
                    }
                })
                set_aptitudeCodeMaster(temp_aptitudeCodeMaster);
                set_personalityCodeMaster(temp_personalityCodeMaster);
                set_motivationCodeCodeMaster(temp_motivationCodeCodeMaster);
            }
        })
    }
    // const getStudentAssessment = (id) => {
    //     __httpStudentAssignment(id).then(res => {
    //         const responseData = res && res.data && res.data.responseData;
    //         if (responseData) {
    //             createCustomData(responseData)
    //         }
    //     })
    // }
    // const createCustomData = (responseData) => {
    //     let temp = [];
    //     responseData.map((item, index) => {
    //         let ele = {
    //             ...item
    //         }
    //         let careerDetil = ele.careerDetil || {}
    //         careerDetil.value = valueArr[index] || 2
    //         ele.careerDetil = careerDetil
    //         temp.push(ele)
    //     })
    //     set_assessment(temp)
    // }
    const handleAssessmentDialog = (item, index) => {
        let temp = {
            isOpen: true,
            title: 'Report',
            categoryId: item.id,
            categoryName: item.name,
            index: index
        }
        set_masterDialogConfig(temp)
    }
    const closeMasterDialog = () => {
        set_masterDialogConfig(null)
    }
    const closeReportMasterDialog = () => {
        set_reportDialogConfig(null)
    }
    const renderImage = (profilePic) => {
        if (profilePic) {
            let url = __getImageurl('student_profile', profilePic)
            return (<img className={style['detail_pick']} src={url} />)
        } else {
            return (<i class="fa fa-user" aria-hidden="true" style={{ 'fontSize': '30px' }}></i>)
        }
    }
    const openReportDialog = () => {
        navigate(`/enrolment/student/report/${params.studentId}`)
        // let temp = {
        //     isOpen: true
        // }
        // set_reportDialogConfig(temp)
    }
    const handleResume = (resumes) => {
        let url = __getImageurl('resumes', resumes);
        window.open(url, '_blank')
    }
    const renderAssessmentStatus = (value) => {
        let temp = 0;
        if (value < 2) {
            temp = 0
        } else if (value == 2) {
            temp = 50
        } else if (value == 3) {
            temp = 75
        } else if (value > 3) {
            temp = 100
        }
        return temp
    }
    return (<>
        {detail ? <>
            <div className={`row ${style['top_bar']}`}>
                <div className='col-sm-12 col-md-6'>
                    <div className='d-flex'>
                        {/* <div style={{ width: 120, height: 120 }}>
                            <CustomCircularProgressBar value={78} />
                        </div> */}
                        <div className={`ml-10 d-flex align-items-center`}>
                            {renderImage(detail?.profilePic || '')}
                            <div className='ml-10'>
                                <p className={style['user_name']}>{detail?.firstName} {detail?.lastName}</p>
                                <p className={style['user_un']}>{detail?.department}</p>
                                <p className={style['user_des']}>{detail?.position}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6'>
                    <div className='d-flex align-items-center justify-content-end'>
                        {/* <div className={style['total_progress']}>
                            <div className={style['heading']}>Progress</div>
                            <ProgressBar value={pCompletion}></ProgressBar>
                            <div className={style['progress_text']}>
                                +5% monthly progress
                            </div>
                        </div> */}
                        <div className={`d-flex flex-column ${style['action_cell']}`}>
                            {/* <CustomButton text="Applied Jobs" className='ml-10 mb-10 btn_next' /> */}
                            {detail && detail.profileStatus > 2 ? <CustomButton onclick={openReportDialog} text="View Reports" className='ml-10 mt-10 btn_transparent' /> : ''}

                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className={style['completion_bar']}>
                    <div className={style['title']}>
                        {pCompletion < 100 ? 'Profile completion' : 'Assessment completion'}
                    </div>
                    {pCompletion < 100 ? <ProgressBar value={pCompletion}></ProgressBar> :
                        <ProgressBar value={renderAssessmentStatus(detail?.profileStatus)}></ProgressBar>
                    }
                </div>
            </div>
            <div className={`row row-same-height ${style['skill_assesment']}`}>
                <div className='col-same-height col-sm-12 col-md-8'>
                    <div className='content'>
                        <div className={`${style['skill_resume']}`}>
                            <div className='d-flex align-items-center'>
                                <div className={style['heading-text']}>Skills</div>
                                {detail && detail?.resumes ? <>
                                    <button type='button' className='btn custom_btn btn_next' onClick={() => { handleResume(detail?.resumes) }}>
                                        <img src={__getImage('/icon_download.svg')} />
                                        &nbsp;
                                        Resume
                                    </button>
                                </>

                                    : ''}
                            </div>
                            <div className={`d-flex flex-wrap ${style['skills']}`}>
                                {detail && detail.skills && detail.skills.length > 0 ? detail && detail.skills && detail.skills.map((item, i) => {
                                    return (
                                        <div key={`skil_${i}`} className={style['skill']}>
                                            <div className={style['inner']}>
                                                {item?.name}
                                            </div>
                                        </div>
                                    )
                                }) : <>
                                    <div key={`int_0`}>No Records...</div>
                                </>}
                            </div>
                        </div>
                        {/* <div className={style['interest_wrap']}>
                            <div className={style['heading-text']}>
                                Interests
                            </div>
                            <div className={`d-flex ${style['intrest_items']}`}>
                                {detail && detail.interests && detail.interests.length > 0 ? <>
                                    {detail.interests.map((ele, i) => {
                                        return (<div key={`int_${i}`} className={style['intrest_item']}>{ele}</div>)
                                    })}
                                </> : <>
                                    <div key={`int_0`}>No Records...</div>
                                </>}

                            </div>
                        </div> */}
                    </div>
                </div>

                {
                    loginType != "employer" &&
                        detail && detail.profileStatus >= 2 ? <div className='col-same-height col-sm-12 col-md-4'>
                        <div className='content'>
                                <div className='isMobileNav'>
                                    <div className={'mt-20'}></div>
                                </div>
                            <div className={style['heading-text']}>360° Assessment</div>
                            <div className={style['assessment_list']}>
                                {aptitudeCodeMaster && aptitudeCodeMaster.top5Careers && aptitudeCodeMaster.top5Careers.length > 0 ? <>
                                    <ul>
                                        {aptitudeCodeMaster.top5Careers.map((item, index) => {
                                            return (
                                                <li key={`aad_${index}`} className={`pointer ${style['item']}`} onClick={() => { handleAssessmentDialog(item, index) }}>
                                                    <div className='d-flex justify-content-between'>
                                                        <span className={style['text1']}>{item.name}</span>
                                                        <span className={style['value1']}>{`${(item.matchPercentage || 0)} %`}</span>
                                                    </div>
                                                    <ProgressBar className='progress-bar-slim no-text' value={(item.matchPercentage || 0)}></ProgressBar>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </> : ''}
                            </div>
                        </div>
                    </div> : ''}
            </div>

            {/* Education */}
            {detail?.educations && detail?.educations.length > 0 ? <>
                <div className={style['careers_match']}>
                    <div className={style['heading-text']}>Education</div>
                    <Carousel value={detail?.educations} itemTemplate={EducationTpl} showNavigators={true} showIndicators={false} numScroll={1} numVisible={2} />
                </div>
            </> : ''}
            {/* skills */}
            {detail?.educations && detail?.educations.length > 0 ? <>
                <div className={style['careers_match']}>
                    <div className={style['heading-text']}>Work Experiances</div>
                    <Carousel value={detail?.workExperiances} itemTemplate={EducationTpl} showNavigators={true} showIndicators={false} numScroll={1} numVisible={2} />
                </div>
            </> : ''}
            {/* Careers matched */}
            {
                loginType != "employer" &&
                    detail && detail.profileStatus >= 2 && aptitudeCodeMaster && aptitudeCodeMaster.top5Careers && aptitudeCodeMaster.top5Careers.length > 0 ? <div className={style['careers_match']}>
                    <div className={style['heading-text']}>Careers matched</div>
                    <Carousel value={aptitudeCodeMaster.top5Careers} itemTemplate={CarrierMatchTpl} showNavigators={true} showIndicators={false} numScroll={1} numVisible={2} />
                </div> : ''
            }

        </> : 'No Records'}

        {masterDialogConfig && masterDialogConfig.isOpen && <AssessmentDetail data={masterDialogConfig} onCloseCallback={closeMasterDialog}></AssessmentDetail>}

        {reportDialogConfig && reportDialogConfig.isOpen && <AssessmentReport aptitudeCodeMaster={aptitudeCodeMaster} personalityCodeMaster={personalityCodeMaster} motivationCodeCodeMaster={motivationCodeCodeMaster} onCloseCallback={closeReportMasterDialog} />}
    </>)
}
export default EnrolmentDetail;