const SuperAdminMenuData = [
    {
        ds_nm: 'Dashboard',
        url: '/dashboard/superadmin',
        icon: 'fa fa-tachometer',
        activeMenuFor: ['/dashboard/superadmin']
    },
    {
        ds_nm: 'Job Listing',
        url: '/job/listing',
        icon: 'fa fa-list',
        activeMenuFor: ['/job/listing']
    },
    {
        ds_nm: 'Student Enrolment',
        url: '/enrolment/list/superadmin',
        icon: 'fa fa-users',
        activeMenuFor: ['/enrolment/list/superadmin']
    },
    // {
    //     ds_nm: 'Assessment',
    //     url: '',
    //     icon:'fa fa-book',
    //     activeMenuFor: ['']
    // },
    // {
    //     ds_nm: 'Student Profile',
    //     url: '',
    //     icon:'fa fa-user',
    //     activeMenuFor: ['']
    // },
    // {
    //     ds_nm: 'Student Assessment Detail',
    //     url: '',
    // },
    // {
    //     ds_nm: 'Tagging Management',
    //     url: '',
    // },
    {
        ds_nm: 'Question Management',
        url: '/question/listing',
        icon: 'fa fa-question-circle',
        activeMenuFor: ['/question/listing', '/question/form']
    },
    {
        ds_nm: 'Job Specification',
        url: '',
        icon: 'fa fa-universal-access',
        activeMenuFor: ['/master/career', '/master/skills'],
        children: [
            {
                ds_nm: 'Career',
                url: '/master/career',
                activeMenuFor: ['/master/career'],
            },
            {
                ds_nm: 'Skill',
                url: '/master/skills',
                activeMenuFor: ['/master/skills'],
            }
        ]
    },
    {
        ds_nm: 'Account Management',
        url: '/account/management',
        icon: 'fa fa-cog',
        activeMenuFor: ['/account/management']
    }
    // {
    //     ds_nm: 'Employee Details',
    //     url: '',
    // }
]
export default SuperAdminMenuData;