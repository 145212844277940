import { combineReducers } from 'redux'
import createReducer from '../createReducer';
const initialState = {
    questionDetail: {

    }
};
const QuestionDetailReducer = createReducer(initialState?.questionDetail)({
    ['ACTION_QUESTION_DETAIL']: (state, action) => action.payload,
})

export default combineReducers({
    _QuestionDetailReducer: QuestionDetailReducer
})