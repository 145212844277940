import React from 'react';
import { __getImage } from '../../../../_factory/globalService';
import style from './index.module.scss';
import { Link } from 'react-router-dom';
const CardReport = ({ data={} }) => {
    return (<Link to={data.landingUrl || '/'}>
        <div className={style['match_card_small']}>
            <div className='d-flex'>
                <div className={style['score_i']}>
                    <img src={__getImage('interview_tile.svg')} />
                </div>
                <div className={style['card_score']}>
                    <p className={style['text_1']}>{data?.title || ''}</p>
                    <p className={style['text_2']}>{data?.value || 0}</p>
                </div>
            </div>
        </div>
    </Link>)
}
export default CardReport;