import { apiConfig } from '../../_factory/apiConfig';
import { HttpPost } from '../../_factory/http-common';
export const __httpSaveQuestion = async (body = {}, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.question.save;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const __httpQuestionList = async (body = {}, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.question.list;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const __httpDeleteQuestion = async (body = {}, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.question.save;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}