import React from 'react';
const Paging = ({ total, perPage, activePage, onPaginationCallback }) => {
    const handlePagination = (e) => {
        onPaginationCallback(e)
    }
    const enablePrevious = () => {
        let temp = false;
        if (activePage > 0) {
            temp = true
        }
        return temp
    }

    const enableNext = () => {
        let temp = false;
        if (total > perPage) {
            if ((activePage * perPage) <= total - 2) {
                temp = true
            }
        }
        return temp
    }
    if (total < perPage) {
        return ''
    }


    return (
        <>
            <div className='d-flex'>
                <button type='button' className={`pgBtn ${enablePrevious() ? '' : 'disabled'}`} onClick={() => { handlePagination(activePage - 1) }}>
                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 9.48454V6.51546L8 0V3.65979L2.31579 7.95876L2.38012 7.76289V8.23711L2.31579 8.04124L8 12.3402V16L0 9.48454Z" fill="#231B37" />
                    </svg>
                </button>
                <button type='button' className={`ml-10 pgBtn ${enableNext() ? '' : 'disabled'}`} onClick={() => { handlePagination(activePage + 1) }}>
                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 9.48454L0 16V12.3402L5.68421 8.04124L5.61988 8.23711V7.76289L5.68421 7.95876L0 3.65979V0L8 6.51546V9.48454Z" fill="#231B37" />
                    </svg>
                </button>
            </div>
        </>
    )
}
export default Paging;