import React from "react";
import { PresonalityTitlesdata } from "../data";
 
const IDPGDataTable = ({ renderValue }) => {
    const renderTitle = () => {
        const row1 = renderValue('SO') > renderValue('G') ? 'SO' : 'G'
        const row2 = renderValue('A') > renderValue('P') ? 'A' : 'P'
        const row3 = renderValue('I') > renderValue('F') ? 'I' : 'F'
        const row4 = renderValue('S') > renderValue('D') ? 'SP' : 'D'
        const key = row3 + row4 + row2 + row1
        let filterArray = PresonalityTitlesdata.filter((pt) => pt.key === key)
        return (
            <>
                {"Presonality Titles"}
                <br /><br />
                {
                    filterArray.length > 0 &&
                    < div className='table_wrapper auto' >
                        <table>
                            <thead>
                                <tr>
                                    <th > {filterArray[0].name}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td >
                                        {filterArray[0].description.split('\n').map((line) => <div style={{ 'margin-top': '15px' }}>{line} </div>)}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div >
                }
            </>

        );
    }
    return (
        renderTitle()
    )
}
export default IDPGDataTable;