import React from "react";
const ErrorMessage = ({ error, text }) => {
  return (
    <div className="text-danger small validation-summary-errors">
      {error && error.data && error.data.reason ? <p>{error.data.reason}</p> : <>
        <p>{error.statusText}</p>
        <ul>
          {JSON.stringify(error.data)}
        </ul>
      </>}

    </div>
  );
};

export default ErrorMessage;
