import React from 'react';
import es from '../enrolment.module.scss';
import { __ExportToExcel, __getImage, __getImageurl, __getLoginDetail, __startEndIndex } from '../../../_factory/globalService';
import Card from '../../../Components/Card';
import { __httpEnrolmentList } from '../enrolmentService';
import DialogWrapper from '../../../Components/Dialog/DialogWrapper';
import StatusDialog from '../../../Components/Dialog/StatusDialog';
import { Link } from "react-router-dom";
import { __httpAllUserList } from '../../../_factory/globalMasterService';
import { AutoComplete } from 'primereact/autocomplete';
import Paging from '../../../Components/Paging';
import ImportFileComponent from '../../../Components/form/ImportFileComponent';
import TableSearchComponent from '../../../Components/TableSearch';
const EnrolmentListing = () => {
    const [listData, set_listData] = React.useState(null);
    const [filteredListData, set_filteredListData] = React.useState(null);
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [isImportFile, set_isImportFile] = React.useState(false);
    const [statusDialogConfig, set_statusDialogConfig] = React.useState(null);
    /* autocomplete */
    const [userList, set_userList] = React.useState(null);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [filteredUserData, setFilteredUserData] = React.useState(null);
    /*  */
    const [loginType, set_loginType] = React.useState(null);
    const [searchTitle, set_searchTitle] = React.useState('Search');
    const [searchParam, set_searchParam] = React.useState({});
    const [activePage, set_activePage] = React.useState(0);
    const [perPage] = React.useState(10);
    const [startIndex, set_startIndex] = React.useState(0);
    const [maxIndex, set_maxIndex] = React.useState(0);
    const [searchInput, set_searchInput] = React.useState('');
    React.useEffect(() => {
        let userData = __getLoginDetail();
        let param = {
            ...searchParam
        }
        if (userData?.type == 'superadmin') {
            set_searchTitle('Filter by University Wise')
            set_loginType(userData?.type)
            getAllUser('university')
        } else if (userData?.type == 'university') {
            param.employerId = userData?.id;
            set_searchParam(param)
        }
        getListingData(param);
    }, []);
    React.useEffect(() => {
        const startEndIndex = __startEndIndex(activePage, perPage);
        set_startIndex(startEndIndex?.startIndex);
        set_maxIndex(startEndIndex?.endIndex)
    }, [activePage])

    React.useEffect(() => {
        filterListingData(searchInput)
    }, [listData])

    const filterListingData = (val = '') => {
        let filteredData = [];
        if (val === "") {
            filteredData = listData || [];
        } else {
            filteredData = (listData || []).filter((item) => {
                let firstName = (item.firstName || '').toLowerCase() || '';
                let lastName = (item.lastName || '').toLowerCase() || '';
                let value = (val || '').toLowerCase()
                return firstName.includes(value) || lastName.includes(value);
            });
        }
        set_filteredListData(filteredData)
    }
    const handleSearch = (value) => {
        set_searchInput(value);
        filterListingData(value)
    }

    const getAllUser = (searchUserType) => {
        let payload = {
            "name": "",
            "type": searchUserType
        }
        __httpAllUserList(payload).then(res => {
            if (res.data.isSuccess) {
                let responseData = res.data.responseData;
                set_userList(responseData)
                set_isDataLoaded(true)
            }
        })
    }
    const handleClearFilter = () => {
        setSelectedUser('')
        let param = {
            employerId: ''
        }
        set_searchParam(param)
        getListingData(param);
    }
    const getListingData = (payload) => {
        let temp = [];
        __httpEnrolmentList(payload).then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = []
            }
            set_listData(temp)
            set_isDataLoaded(true)
        })
    }
    const handleDataupload = (config) => {
        closeImportFile();
        set_statusDialogConfig(config)
        setTimeout(() => {
            set_statusDialogConfig(null)
            handleClearFilter()
        }, 2000)
    }
    const handleOnClickCallBack = (e) => {
        if (e && e.status) {
            getListingData()
        }
        set_statusDialogConfig(null)
    }
    const openImportFile = () => {
        set_isImportFile(true)
    }
    const closeImportFile = () => {
        set_isImportFile(false)
    }
    const handleAutoCompleteChange = (e) => {
        setSelectedUser(e.value)

    }
    const handleONSelect = (e) => {
        let param = {
            employerId: e.value.id || ''
        }
        set_searchParam(param)
        getListingData(param);
    }

    const search = (event) => {
        setTimeout(() => {
            let _filteredUserData;
            if (!event.query.trim().length) {
                _filteredUserData = [...userList];
            }
            else {
                _filteredUserData = userList.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredUserData(_filteredUserData);
        }, 250);
    }
    const handleOnPaginationCallback = (pageNumber) => {
        set_activePage(pageNumber)
    }
    const ExportToExcel = (type, id, name) => {
        __ExportToExcel(type, id, name)
    }
    const renderImage = (profilePic) => {
        if (profilePic) {
            let url = __getImageurl('student_profile', profilePic)
            return (<img src={url} className={es['tableImg']} />)
        } else {
            return (<i class="fa fa-user" aria-hidden="true" style={{ 'font-size': '30px' }}></i>)
        }
    }
    return (<>
        <>
            <Card>
                <div className={es['enrollment_wrapper']}>
                    <div className={'plr-25'}>
                        <div className='row'>
                            <div className='col-sm-12 col-md-9'>
                                <div className='row'>
                                    {loginType == 'superadmin' ? <div className='col-md-4 col-sm-12'>
                                        <div className='form-group'>
                                            <div className='search_bar'>
                                                <div className='custom-autocomplete width_icon search_inner'>
                                                    <img className={`search_icon ${es['search_icon']}`} src={__getImage('search_icon.svg')} />
                                                    <AutoComplete field="name" value={selectedUser} suggestions={filteredUserData} completeMethod={search} onChange={(e) => handleAutoCompleteChange(e)} placeholder={searchTitle} onSelect={(e) => { handleONSelect(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''}
                                    <div className='col-md-4 col-sm-12'>
                                        <div className='form-group'>
                                            <TableSearchComponent placeholder='Search by name' searchInput={searchInput} handleSearch={handleSearch} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-3'>
                                <div className=''>
                                    <div className='d-flex plr-10 justify-content-end width_100'>
                                        {/* <button type='button' className='btn custom_btn btn_next no-wrap'>
                                            <img src={__getImage('filtersearch.svg')} />
                                            Filter
                                        </button> */}
                                        <button type='button' className={`ml-10 btn custom_btn btn_transparent no-wrap`} onClick={() => { handleClearFilter() }}>
                                            <img src={__getImage('filtersearch.svg')} />
                                            &nbsp;
                                            Clear Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className={es['page_header']}>
                        <div>
                            <div className={es['page_title']}>
                                List of Enrolments
                            </div>
                            {isDataLoaded && filteredListData && <div className={es['count']}>{filteredListData.length} applications</div>}
                        </div>
                        <div className='d-flex'>
                            {loginType !== 'superadmin' && <button type='button' className={es['btn_primary']} onClick={(e) => { openImportFile() }} id="btnExport">
                                <img src={__getImage('filtersearch.svg')} />
                                Import
                            </button>}
                            {loginType !== 'superadmin' && <button type='button' className={`ml-10 ${es['btn_primary']}`} onClick={() => { ExportToExcel('xlsx', 'sampleTable', 'Student Sample List') }}>
                                <img src={__getImage('filtersearch.svg')} />
                                Download Sample
                            </button>}
                            <button type='button' className={`ml-10 ${es['btn_primary']}`} onClick={() => { ExportToExcel('xlsx', 'enrolmentTable', 'Enrolment List') }}>
                                <img src={__getImage('exportsquare.svg')} />
                                Export
                            </button>
                        </div>
                    </div>
                    <div className={`table_wrapper auto display-hidden ${es['table_wrapper']}`}>
                        <table id="sampleTable">
                            <thead>
                                <tr>
                                    <th>name</th>
                                    {/* <th>department</th> */}
                                    <th>email</th>
                                    <th>password</th>
                                    <th>careersMatch</th>
                                    <th>position</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className={`table_wrapper scrollBar ${es['table_wrapper']}`}>
                        <table id="enrolmentTable">
                            <thead>
                                <tr>
                                    <th>
                                        <div className='content'>
                                            Name
                                        </div>
                                    </th>
                                    {/* <th>
                                        <div className='content'>
                                            Department
                                        </div>
                                    </th> */}
                                    <th>

                                        <div className='content'>
                                            Email
                                        </div>
                                    </th>
                                    <th>
                                        <div className='content'>
                                            Careers Matched
                                        </div>
                                    </th>
                                    <th>
                                        <div className='content'>
                                            Position
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isDataLoaded ? <tr>
                                    <td colSpan={100}>
                                        <div className='content'>

                                        </div>
                                    </td>
                                </tr> : <>
                                    {filteredListData && filteredListData.length > 0 ? <>
                                        {filteredListData && filteredListData.slice(startIndex, maxIndex).map((ele, i) => {
                                            return (
                                                <tr key={`en_${i}`}>
                                                    <td>
                                                        <Link to={`/enrolment/student/detail/${ele.id}`}>
                                                            <div className='d-flex pointer'>
                                                                <div className={es['thumb']}>
                                                                    {renderImage(ele?.profilePic)}
                                                                </div>
                                                                <div className='content'>
                                                                    {ele.firstName}  {ele.lastName}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    {/* <td>
                                                        <div className='content'>
                                                            {ele.department}
                                                        </div>
                                                    </td> */}
                                                    <td>
                                                        <div className='content'>
                                                            {ele.email}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='content'>
                                                            {ele.careersMatch}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='content'>
                                                            {ele.position}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </> : <>
                                        <tr>
                                            <td colSpan={100}>
                                                <div className='content'>
                                                    No records...
                                                </div>
                                            </td>
                                        </tr>
                                    </>}
                                </>}
                            </tbody>
                        </table>
                    </div>
                    {isDataLoaded && filteredListData && filteredListData.length > 0 ? <div className='pagers'>
                        <div></div>
                        <Paging total={filteredListData.length} perPage={perPage} activePage={activePage} onPaginationCallback={handleOnPaginationCallback} />
                    </div> : ''}
                </div>
            </Card>
            {isImportFile && <DialogWrapper className={'width_6 00'}>
                <div className='modal-header'>
                    <h5 className="modal-title">Import List</h5>
                    <div className='dialog-close'>
                        <img src={__getImage('/dialog_close.svg')} onClick={() => { closeImportFile() }} />
                    </div>
                </div>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='form-group text-left'>
                                <label>Choose File</label>
                                <ImportFileComponent placeholder={"Upload List"} emitDataUpload={handleDataupload} />
                            </div>
                        </div>
                    </div>
                </div>
            </DialogWrapper>}
            {statusDialogConfig && <StatusDialog body={statusDialogConfig.body} isTitle={true} isSuccess={statusDialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={statusDialogConfig.isFooter} />}
        </>
    </>)
}
export default EnrolmentListing;