import React from 'react';
import styles from './chooseAccount.module.scss';
import { StaticData } from './data';
import { __getImage } from '../../_factory/globalService';
export const ChooseAccount = ({ handleAccountClick }) => {
    const handleOnClick = (ele) => {
        handleAccountClick(ele.urlFragment)
    }
    return (
        <div className={`page_wrapper ${styles['page_wrapper']}`}>
            <div className={styles['page_content']}>
                <div className='container'>
                    <h1 className={styles.pageTitle}>{StaticData.title}</h1>
                    <p className={styles.desc}>
                        Kindly read our <a>privacy policy</a> and <a>terms and conditions</a>. If you need more information, please check out <a>Help page</a>
                    </p>
                    <div className={`row-same-height ${styles.accountTypeTiles}`}>
                        {StaticData.accountType.map((ele, index) => {
                            return <div key={`acT_${index}`} className={`col-same-height ${styles['col-same-height']}`} onClick={() => { handleOnClick(ele) }}>
                                <div className={`content ${styles.content}`}>
                                    <div className={`text-center ${styles['inner_content']}`}>
                                        <div className={`text-center ${styles['thumb']}`}>
                                            <img src={__getImage(ele.img)} />
                                        </div>
                                        {ele.type}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ChooseAccount;