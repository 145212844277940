import React from 'react';
import styles from './dashboard.module.scss';
import { DashboardData } from './data';
import DashboardSuperAdmin from './DashboardSuperAdmin';
import DashboardStudent from './DashboardStudent';
import DashboardEmployer from './DashboardEmployer';
import { __getLoginDetail } from '../../_factory/globalService';
import { __httpAppliedJobList, __httpJobList } from '../Job/JobServicec';
import { __httpEnrolmentList } from '../Enrolments/enrolmentService';
import { __httpAllUserList } from '../../_factory/globalMasterService';
import { __httpQuestionList } from '../Questions/QuestionService';
import DashboardAdmin from './DashboardAdmin';
import { __httpFetchRM } from '../ReportingManager/rmService';
const Dashboard = () => {
    const [userInfo, set_userInfo] = React.useState(null);
    const [jobListData, set_jobListData] = React.useState(null);
    const [studentListData, set_studentListData] = React.useState(null);
    const [userListData, set_userListData] = React.useState(null);
    const [questionListData, set_questionListData] = React.useState(null);
    const [appliedJobListData, set_appliedJobListData] = React.useState(null);
    const [topQuestions, set_topQuestions] = React.useState(null);
    const [topDepartments, set_topDepartments] = React.useState(null);
    const [topJobs, set_topJobs] = React.useState(null);
    const [rmMaster, set_rmMaster] = React.useState([]);
    React.useEffect(() => {
        let userData = __getLoginDetail();
        if (userData && userData.type == 'superadmin') {
            getJobListing({});
            getStudentList();
            getUserList();
            getQuestionListingData();
        } else if (userData && userData.type == 'university') {
            let param = {
                employerId: userData?.id
            }
            getStudentList(param);
        } else if (userData && userData.type == 'admin') {
            getJobListing({});
            getStudentList();
        } else if (userData && userData.type == 'employer') {
            let param = {
                employerId: userData?.id
            }
            getJobListing(param);
            Get_RM_Master(userData?.id)
            getAppliedJobListData(userData.id);

        }
        set_userInfo(userData);
    }, [])
    const Get_RM_Master = async (id) => {
        let res = await __httpFetchRM(id, {});
        let temp = [];
        if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
            temp = res.data.responseData;
        } else {
            temp = []
        }
        set_rmMaster(temp)
    }
    const getJobListing = (param = {}) => {
        let temp = []
        __httpJobList(param).then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = [];
            }
            set_jobListData(temp)
        })
    }
    const getStudentList = (param = {}) => {
        let temp = [];
        __httpEnrolmentList(param).then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = []
            }
            createStudentReport(temp)
            set_studentListData(temp)
        })
    }
    const getUserList = (body) => {
        let temp = [];
        __httpAllUserList(body).then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = []
            }
            set_userListData(temp)
        })
    }
    const getQuestionListingData = () => {
        const loader = document.getElementById("loader2");
        if (loader) {
            loader.style.display = "block";
        }
        __httpQuestionList().then(res => {
            let temp = [];
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = []
            }
            createQuestionReport(temp)
            set_questionListData(temp)
        }).finally(() => {
            const loader = document.getElementById("loader2");
            if (loader) {
                loader.style.display = "none";
            }
        })
    }
    const getAppliedJobListData = (id) => {
        __httpAppliedJobList(id).then(res => {
            let temp = [];
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = [];
            }
            createJobReport(temp)
            set_appliedJobListData(temp)
        })
    }
    const createQuestionReport = (list) => {
        let groupingViaCommonProperty = Object.values(
            list.reduce((acc, current) => {
                acc[current.category] = acc[current.category] ?? [];
                acc[current.category].push(current);
                return acc;
            }, {})
        );
        set_topQuestions(groupingViaCommonProperty)
    }

    const createStudentReport = (list) => {
        let groupingViaCommonProperty = Object.values(
            list.reduce((acc, current) => {
                if (current.department) {
                    acc[current.department] = acc[current.department] ?? [];
                    acc[current.department].push(current);
                }

                return acc;
            }, {})
        );
        set_topDepartments(groupingViaCommonProperty)
    }

    const createJobReport = (list) => {
        let groupingViaCommonProperty = Object.values(
            list.reduce((acc, current) => {
                if (current && current.jobDetail && current.jobDetail.title) {
                    acc[current.jobDetail.title] = acc[current.jobDetail.title] ?? [];
                    acc[current.jobDetail.title].push(current);
                }
                return acc;
            }, {})
        );
        set_topJobs(groupingViaCommonProperty)
    }
    const renderHtml = () => {
        if (userInfo) {
            if (userInfo.type == 'superadmin') {
                const jobInfo = {
                    title: 'Total Job Posted',
                    value: jobListData && jobListData.length || 0,
                    landingUrl: '/job/listing'
                }
                const studentInfo = {
                    title: 'Total Student Enrollments',
                    value: studentListData && studentListData.length || 0,
                    landingUrl: '/enrolment/list/superadmin'
                }
                const questionInfo = {
                    title: 'Total Question Created',
                    value: questionListData && questionListData.length || 0,
                    landingUrl: '/question/listing'
                }

                const accountInfo = {
                    title: 'Total Account Created',
                    value: userListData && userListData.length || 0,
                    landingUrl: '/account/management'
                }
                return <>
                    <DashboardSuperAdmin styles={styles} jobInfo={jobInfo} studentInfo={studentInfo} questionInfo={questionInfo} accountInfo={accountInfo} topQuestions={topQuestions} />
                </>
            } else if (userInfo.type == 'university') {
                const studentInfo = {
                    title: 'Total Student Enrollments',
                    value: studentListData && studentListData.length || 0,
                    landingUrl: '/enrolment/list/superadmin'
                }
                return <>
                    <DashboardStudent userInfo={userInfo} DashboardData={DashboardData} styles={styles} studentInfo={studentInfo} topDataReport={topDepartments} />
                </>
            } else if (userInfo.type == 'employer') {
                const jobInfo = {
                    title: 'Total Job Posted',
                    value: jobListData && jobListData.length || 0,
                    landingUrl: '/job/listing'
                }

                const appliedJobInfo = {
                    title: `Total Applied Job's`,
                    value: appliedJobListData && appliedJobListData.length || 0,
                    landingUrl: '/job/listing/applied'
                }
                const rmInfo = {
                    title: `Total Reporting Manager's`,
                    value: rmMaster && rmMaster.length || 0,
                    landingUrl: '/job/listing/applied'
                }
                return <>
                    <DashboardEmployer styles={styles} jobInfo={jobInfo} appliedJobInfo={appliedJobInfo} topDataReport={topJobs} rmInfo={rmInfo} />
                </>
            } else if (userInfo.type == 'admin') {
                const jobInfo = {
                    title: 'Total Job Posted',
                    value: jobListData && jobListData.length || 0,
                    landingUrl: '/job/listing'
                }
                const studentInfo = {
                    title: 'Total Student Enrollments',
                    value: studentListData && studentListData.length || 0,
                    landingUrl: '/enrolment/list/superadmin'
                }
                return <>
                    <DashboardAdmin styles={styles} jobInfo={jobInfo} studentInfo={studentInfo} topDataReport={topDepartments} />
                </>
            } else {
                return ''
            }
        } else {
            return ''
        }
    }
    return (
        renderHtml()
    )
}
export default Dashboard;