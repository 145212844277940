import { combineReducers } from 'redux'
import createReducer from '../createReducer';
const initialState = {
    profileDetail: {

    }
};
const ProfileDetailReducer = createReducer(initialState?.profileDetail)({
    ['ACTION_PROFILE_DETAIL']: (state, action) => action.payload,
})

export default combineReducers({
    _ProfileDetailReducer: ProfileDetailReducer
})