import React from "react";
import DialogWrapper from "../../../Components/Dialog/DialogWrapper";
import { __getImage } from "../../../_factory/globalService";
import { StudentDetailData } from "./data";
import { TabView, TabPanel } from 'primereact/tabview';
import HorizontalBar from '../../../Components/Chart/HBarChart';
import { __httpAssignmentMaster } from "../enrolmentService";
const AssessmentDetail = ({ data, onCloseCallback }) => {
    const [listData, set_listData] = React.useState([])
    const [chartData, set_chartData] = React.useState(null)
    React.useEffect(() => {
        getAssessmentMaster()
    }, []);

    const getAssessmentMaster = () => {
        __httpAssignmentMaster().then(res => {
            const responseData = res && res.data && res.data.responseData;
            if (responseData) {
                createCustomData(responseData)
            }
        })
    }
    const createCustomData = (responseData) => {
        let selectedCategory = null
        if (responseData && responseData.length > 0) {
            responseData.map((item, index) => {
                let category = data.categoryName
                if (item.career == category) {
                    selectedCategory = item
                }
            })
        }
        createCustomCategory(selectedCategory)
    }
    const createCustomCategory = (selectedCategory) => {
        let temp = []
        for (const key in selectedCategory) {
            if (key != 'id' && key != 'career') {
                let item = {
                    label: key,
                    value: selectedCategory[key],
                    color: ''
                }
                temp.push(item)
            }

        }
        createAssessmentData(temp)
        set_listData(temp)

    }
    const createAssessmentData = (list) => {
        const valueArr = StudentDetailData.valueArr[data.index];
        let temp = [];
        list.map((item, index) => {
            let value = valueArr && valueArr.data[index] || item.value;
            const scoreObj = createScore(value);
            let ele = {
                ...item,
                value: value,
                scoreObj: scoreObj
            }
            temp.push(ele)
        })
        createChartData(temp)
    }
    const createChartData = (data) => {
        let tempLabels = [];
        let tempData = [];
        let temp_backgroundColor = []
        data && data.length > 0 && data.map((item, index) => {
            tempData.push(item.value)
            tempLabels.push(item.label);
            temp_backgroundColor.push(`rgba(${item?.scoreObj?.color || '#000'})`)
        })
        let temp = {
            labels: tempLabels,
            datasets: [
                {
                    label: 'Report',
                    data: tempData,
                    backgroundColor: temp_backgroundColor,
                },
            ]
        }
        set_chartData(temp)
    }
    const createScore = (value) => {
        let temp = ''
        StudentDetailData.scoreArr.map((item, index) => {
            if (item.range.indexOf(value) > -1) {
                temp = item
            }
        })
        return temp
    }
    const scoreText = (value) => {
        let temp = ''
        StudentDetailData.scoreArr.map((item) => {
            if (item.range.indexOf(value) > -1) {
                temp = item
            }
        })
        return temp
    }
    const handleOnClickCallBack = () => {
        onCloseCallback()
    }
    return (
        <DialogWrapper className={'width_500'}>
            <div className='modal-header'>
                <h5 className="modal-title">{data && data.categoryName && data.categoryName || 'Assessment'}</h5>
                <div className='dialog-close'>
                    <img src={__getImage('/dialog_close.svg')} onClick={(e) => { handleOnClickCallBack(null) }} />
                </div>
            </div>
            <div className='modal-body'>
                <div className="theme_tab">
                    <TabView>
                        <TabPanel header="List View">
                            <p className="m-0">
                                <div className='table_wrapper auto'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Skill
                                                </th>
                                                <th>Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listData && listData.length > 0 && listData.map((item, index) => {
                                                const score = scoreText(item.value);
                                                return (
                                                    <tr key={`skill_${index}`}>
                                                        <td>
                                                            {item.label}
                                                        </td>
                                                        <td>
                                                            {score?.label}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </p>
                        </TabPanel>
                        <TabPanel header="Chart View">
                            <p className="m-0">
                                {chartData && <HorizontalBar data={chartData} />}
                            </p>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </DialogWrapper>
    )
}
export default AssessmentDetail