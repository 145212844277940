import React from "react";
import { Field, Form } from "react-final-form";
import { combineValidators, composeValidators, isRequired } from "revalidate";
import ErrorMessage from "../../../Components/form/ErrorMessage";
import CustomButton from "../../../Components/form/CustomButton";
import InputTextComponent from "../../../Components/form/InputText";
import InputNumber from "../../../Components/form/InputNumber";
import customValidations from "../../../_factory/_validation";
import { __saveReportingManager } from "../rmService";
import { __getLoginDetail } from "../../../_factory/globalService";
const RMFormComponent = ({ formValue = {}, handleOnClickCallBack, actionType }) => {
    const [initialValue, set_initialValue] = React.useState({});
    React.useEffect(() => {
        set_initialValue(formValue)
    }, [formValue])
    const validate = combineValidators({
        name: composeValidators(
            isRequired,
        )("Name"),
        email: composeValidators(
            customValidations.isValidEmail(),
            isRequired,
        )("Email"),
        mobileNumber: composeValidators(
            isRequired,
            customValidations.fixedLength(10)
        )("Mobile Number")
    });
    const onSubmit = async (values) => {
        let res = await __saveReportingManager(values);
        handleOnClickCallBack(res)
    }
    return (
        <div className='form_wrapper'>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValue || {}}
                validate={validate}
                render={({
                    handleSubmit,
                    submitError,
                    values,
                    dirtySinceLastSubmit,
                }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className='row'>
                            <div className='col-sm-12 col-md-6'>
                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="name"
                                        component={InputTextComponent}
                                        placeholder="Name"
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6'>
                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="email"
                                        component={InputTextComponent}
                                        placeholder="Email"
                                        isDisabled={actionType == 1 ? true : false}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6'>
                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="mobileNumber"
                                        component={InputNumber}
                                        placeholder="Mobile Number"
                                        maxlength={10}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className=" text-right">
                                {submitError && !dirtySinceLastSubmit && (
                                    <ErrorMessage
                                        error={submitError}
                                        text="Error during submit your form."
                                    />
                                )}
                                <div className='form-group txt-center'>
                                    <CustomButton type={'submit'} text='Submit' className='btn_next' />
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            />
        </div>
    )
}
export default RMFormComponent;