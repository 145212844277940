import React from 'react';
import { __getImage } from '../../../_factory/globalService';
import DialogWrapper from '../../../Components/Dialog/DialogWrapper';
import { Field, Form } from 'react-final-form';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import InputText from '../../../Components/form/InputText';
import PasswordInput from '../../../Components/form/Password';
import CustomButton from '../../../Components/form/CustomButton';
import ErrorMessage from '../../../Components/form/ErrorMessage';
import customValidations from '../../../_factory/_validation';
import SelectInput from '../../../Components/form/SelectInput';
import { _httpSignUp } from '../../Auth/AuthService';
import { __httpUpdateProfile } from '../../Profile/profileService';
import { accountType } from '../../../_factory/_constants';
import InputTextAreaComponent from '../../../Components/form/InputTextAreaComponent';
import StatusDialog from '../../../Components/Dialog/StatusDialog';
const AccountFormComponent = ({ data, onCloseCallback }) => {
    const [initialValue, set_initialValue] = React.useState({});
    const [statusDialogConfig, set_statusDialogConfig] = React.useState(null);
    React.useEffect(() => {
        set_initialValue(data?.formValue || {})
    }, [])
    const validate = combineValidators({
        name: composeValidators(
            isRequired,
        )("Name"),
        email: composeValidators(
            isRequired,
            customValidations.isValidEmail()
        )("Email"),
        password: composeValidators(
            isRequired,
        )("Password"),
        type: composeValidators(
            isRequired,
        )("Type")
    });
    const handleOnClickCallBack = (e) => {
        if (onCloseCallback) {
            onCloseCallback(e)
        }
    }
    const onSubmit = async (values) => {
        let payload = {};
        let res;
        if (data?.type == 'edit') {
            if (values.type == "employer") {
                payload = {
                    id: values?.id || '',
                    name: values?.name || '',
                    password: values?.password || '',
                    companyName: values?.companyName || '',
                    companyDescription: values?.companyDescription || '',
                }
            } else if (values.type == "university") {
                payload = {
                    id: values?.id || '',
                    name: values?.name || '',
                    password: values?.password || ''
                }
            } else if (values.type == "superadmin") {
                payload = {
                    id: values?.id || '',
                    name: values?.name || '',
                    password: values?.password || ''
                }
            } else if (values.type == "admin") {
                payload = {
                    id: values?.id || '',
                    name: values?.name || '',
                    password: values?.password || ''
                }
            }
            res = await __httpUpdateProfile(payload)
        } else {
            payload = {
                ...values
            }
            res = await _httpSignUp(payload);
        }
        if (res && res.data) {
            if (res.data.isSuccess) {
                let config = {
                    body: res?.data?.responseMessage || 'User Data saved successfully',
                    isSuccess: true,
                    isFooter: false
                }
                set_statusDialogConfig(config)
                setTimeout(() => {
                    set_statusDialogConfig(null)
                    handleOnClickCallBack({
                        isReload: true
                    })
                }, 2000)
            } else {
                let config = {
                    body: res?.data?.responseMessage || 'Something went wrong',
                    isSuccess: false,
                    isFooter: true
                }
                set_statusDialogConfig(config)
            }
        } else {
            let config = {
                body: 'Something went wrong',
                isSuccess: false,
                isFooter: true
            }
            set_statusDialogConfig(config)
        }
    }
    return (<>
        <DialogWrapper className={'width_900'}>
            <div className='modal-header'>
                <h5 className="modal-title">{data?.title}</h5>
                <div className='dialog-close'>
                    <img src={__getImage('/dialog_close.svg')} onClick={(e) => { handleOnClickCallBack(null) }} />
                </div>
            </div>
            <div className='modal-body'>
                <div className='form_wrapper'>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={initialValue || {}}
                        validate={validate}
                        render={({
                            handleSubmit,
                            submitError,
                            values,
                            dirtySinceLastSubmit,
                        }) => (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <div className='row'>
                                    <div className='col-sm-12 col-md-6'>
                                        <div className="form-group">
                                            <Field
                                                name="type"
                                                component={SelectInput}
                                                options={accountType}
                                                placeholder={values?.type ? 'Account Type' : 'Select Type'}
                                                nameKey={'name'}
                                                valueKey='value'
                                                isShowClear={values?.type ? true : false}
                                                isDisabled={data?.type == 'edit' ? true : false}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-6'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="name"
                                                component={InputText}
                                                placeholder="Name"
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-6'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="email"
                                                component={InputText}
                                                placeholder="Email"
                                                isDisabled={data?.type == 'edit' ? true : false}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-6'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="password"
                                                component={PasswordInput}
                                                placeholder="Password"
                                            />
                                        </div>
                                    </div>
                                    {values?.type == 'employer' ? <div className='col-sm-12 col-md-6'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="companyName"
                                                component={InputText}
                                                placeholder="Company Name"
                                            />
                                        </div>
                                    </div> : ''}
                                    {values?.type == 'employer' ? <div className='col-sm-12 col-md-12'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="companyDescription"
                                                component={InputTextAreaComponent}
                                                placeholder="Company Description"
                                            />
                                        </div>
                                    </div> : ''}
                                </div>
                                <div className="form-group">
                                    <div className=" text-right">
                                        {submitError && !dirtySinceLastSubmit && (
                                            <ErrorMessage
                                                error={submitError}
                                                text="Error during submit your form."
                                            />
                                        )}
                                        <div className='form-group txt-center'>
                                            <CustomButton type={'submit'} text='Submit' className='btn_next' />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
        </DialogWrapper>
        {statusDialogConfig && <StatusDialog body={statusDialogConfig.body} isTitle={true} isSuccess={statusDialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={statusDialogConfig.isFooter} />}
    </>)
}
export default AccountFormComponent;