import React from 'react';
import { __httpEnrolmentList } from '../../Enrolments/enrolmentService';
import Card from '../../../Components/Card';
import { __ExportToExcel, __getImage, __getLoginDetail, __startEndIndex } from '../../../_factory/globalService';
import style from './listinig.module.scss';
import { Link } from "react-router-dom";
import { __httpJobList, __httpJobPost } from '../JobServicec';
import JobPostForm from '../JobPostForm/JobPostForm';
import { JobCreateField } from '../../../_modal/JobModal';
import JobPostView from '../JobPostForm/JobPostView';
import { __getMasterList, __httpAllUserList } from '../../../_factory/globalMasterService';
import { AutoComplete } from "primereact/autocomplete";
import Paging from '../../../Components/Paging';
import TableSearchComponent from '../../../Components/TableSearch';
import { Dropdown } from 'primereact/dropdown';
import ConfirmDialog from '../../../Components/Dialog/ConfirmDialog';
import StatusDialog from '../../../Components/Dialog/StatusDialog';
const JobListingComponent = () => {
    const [listData, set_listData] = React.useState(null);
    const [filteredListData, set_filteredListData] = React.useState(null);
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [formDialogConfig, set_formDialogConfig] = React.useState(null);
    const [formViewDialogConfig, set_formViewDialogConfig] = React.useState(null);
    /* auto complete */
    const [userList, set_userList] = React.useState(null);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [filteredUserData, setFilteredUserData] = React.useState(null);
    /*  */
    const [searchParam, set_searchParam] = React.useState({});
    const [loginType, set_loginType] = React.useState(null);
    const [searchTitle, set_searchTitle] = React.useState('Search');
    /* paging */
    const [activePage, set_activePage] = React.useState(0);
    const [perPage] = React.useState(10);
    const [startIndex, set_startIndex] = React.useState(0);
    const [maxIndex, set_maxIndex] = React.useState(0);
    const [selectedId, set_selectedId] = React.useState(null);
    const [confirmDialogConfig, set_confirmDialogConfig] = React.useState(null);
    const [statusDialogConfig, set_statusDialogConfig] = React.useState(null);
    const [filterParams, set_filterParams] = React.useState({
        title: '',
        industry: ''
    })
    const [industryMaster, set_industryMaster] = React.useState([]);
    React.useEffect(() => {
        gerCarrierMaster()
        let userData = __getLoginDetail();
        let param = {
            ...searchParam
        }
        /* todo */
        if (userData?.type == 'superadmin') {
            set_searchTitle('Filter by employer wise')
            set_loginType(userData?.type)
            getAllUser('employer');
        } else if (userData?.type == 'employer') {
            param.employerId = userData?.id;
            set_searchParam(param)
        }
        getListingData(param);
    }, []);

    React.useEffect(() => {
        const startEndIndex = __startEndIndex(activePage, perPage);
        set_startIndex(startEndIndex?.startIndex);
        set_maxIndex(startEndIndex?.endIndex)
    }, [activePage]);

    React.useEffect(() => {
        if (listData && listData.length > 0) {
            filterListingData(filterParams || '')
        }
    }, [listData]);

    /* Get Masters */
    const gerCarrierMaster = async () => {
        let body = {
            "type": 'career'
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_industryMaster(responseData)
    }
    const getAllUser = (searchUserType) => {
        let payload = {
            "name": "",
            "type": searchUserType
        }
        __httpAllUserList(payload).then(res => {
            if (res.data.isSuccess) {
                let responseData = res.data.responseData;
                set_userList(responseData)
                set_isDataLoaded(true)
            }
        })
    }
    const getListingData = (body) => {

        let temp = []
        __httpJobList(body).then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = [];
            }
            set_listData(temp)
            set_isDataLoaded(true)
        })
    }

    const filterListingData = (filterObj = {}) => {
        let filteredData = [];
        if (listData && listData.length > 0) {
            let title = filterObj && filterObj.title && filterObj.title.toLowerCase() || '';
            let industry = filterObj && filterObj.industry && filterObj.industry.toLowerCase() || '';
            if (!title && !industry) {
                filteredData = listData || [];
            } else {
                filteredData = (listData || []).filter((item) => {
                    const itemTitle = item && item.title && item.title.toLowerCase() || '';
                    const itemIndustry = item && item.industry && item.industry.toLowerCase() || '';
                    if (itemTitle.includes(title) && itemIndustry.includes(industry)) {
                        return true
                    }
                });
            }
        } else {
            filteredData = listData || []
        }

        set_filteredListData(filteredData)
    }
    const openJobView = (data) => {
        let temp = {
            isOpen: true,
            isReload: true,
            title: 'View Job Detail',
            formValue: {
                ...data
            }
        }
        set_formViewDialogConfig(temp)
    }
    const openJobForm = (data) => {
        let fieldValue = {
            ...JobCreateField
        }
        if (data) {
            for (const key in fieldValue) {
                if (data.hasOwnProperty(key)) {
                    fieldValue[key] = data[key]
                }
            }
            fieldValue.id = data?.id;
        }

        let temp = {
            isOpen: true,
            isReload: true,
            title: 'Add Job',
            formValue: {
                ...fieldValue
            }
        }
        set_formDialogConfig(temp)
    }
    const handleOnCloseCallback = (res) => {
        if (res) {
            if (res.isReload) {
                getListingData(searchParam);
                set_formDialogConfig(res)
                let config = {
                    body: res?.data?.responseMessage || 'User Data saved successfully',
                    isSuccess: true,
                    isFooter: false
                }
                set_statusDialogConfig(config);
                setTimeout(() => {
                    set_statusDialogConfig(null);
                }, 2000)
            } else {
                let config = {
                    body: res?.data?.responseMessage || 'Something went wrong',
                    isSuccess: false,
                    isFooter: true
                }
                set_statusDialogConfig(config);
            }
        } else {
            set_formDialogConfig(null)
        }

    }
    const handleOnCloseViewCallback = () => {
        set_formViewDialogConfig(null)
    }
    const search = (event) => {
        setTimeout(() => {
            let _filteredUserData;
            if (!event.query.trim().length) {
                _filteredUserData = [...userList];
            }
            else {
                _filteredUserData = userList.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredUserData(_filteredUserData);
        }, 250);
    }
    const handleAutoCompleteChange = (e) => {
        setSelectedUser(e.value)

    }
    const handleONSelect = (e) => {
        let param = {
            employerId: e.value.id || ''
        }
        set_searchParam(param)
        getListingData(param);
    }

    const handleOnPaginationCallback = (pageNumber) => {
        set_activePage(pageNumber)
    }
    const ExportToExcel = (type, id, name) => {
        __ExportToExcel(type, id, name)
    }

    const handleSearch = (value) => {
        let temp = {
            ...filterParams,
            title: value
        }
        set_filterParams(temp);
        setTimeout(() => {
            filterListingData(temp)
        }, 250)
    }

    const handleFilter = (value) => {
        let temp = {
            ...filterParams,
            industry: value
        }
        set_filterParams(temp);
        filterListingData(temp);
    }
    const handleClearFilter = () => {
        let temp = {
            title: '',
            industry: ''
        }
        set_filterParams(temp);
        filterListingData(temp);
    }
    const handleDeleteRecord = (id) => {
        // let cDC = {
        //     isOpen: true,
        //     title: 'Message',
        //     body: 'Are you sure you want to delete ?'
        // }
        // set_selectedId(id)
        // set_confirmDialogConfig(cDC)
    }
    const handle_onNoClickCallBack = () => {
        set_confirmDialogConfig(null)
    }
    const handle_onYesClickCallBack = () => {
        let id = selectedId;
        let postDto = {
            "id": id,
            "employeeType": "-1"
        }
        __httpJobPost(postDto).then(res => {
            set_confirmDialogConfig(null)
            let config = {}
            if (res && res.data) {
                if (res.data.isSuccess) {
                    config = {
                        isOpen: true,
                        body: res.data.responseMessage || 'Record deleted successfully',
                        isSuccess: true,
                        isFooter: false
                    }
                    set_statusDialogConfig(config)
                    setTimeout(() => {
                        set_statusDialogConfig(null);
                        getListingData(searchParam)
                    }, 2000)
                } else {
                    config = {
                        body: res?.data?.responseMessage || 'Something went wrong',
                        isSuccess: false,
                        isFooter: true
                    }
                    set_statusDialogConfig(config)
                }
            } else {
                config = {
                    body: 'Something went wrong',
                    isSuccess: false,
                    isFooter: true
                }
                set_statusDialogConfig(config)
            }

            set_selectedId(null)
        })
    }
    const handleOnClickCallBack = () => {
        set_statusDialogConfig(null)
    }
    return (<>
        <Card>
            <div className={style['enrollment_wrapper']}>
                <div className={'plr-25'}>
                    <div className='row'>
                        <div className='col-sm-12 col-md-9'>
                            <div className='row'>
                                {loginType == 'superadmin' ? <div className='col-md-4 col-sm-12'>
                                    <div className='form-group'>
                                        <div className='search_bar'>
                                            <div className='custom-autocomplete width_icon search_inner'>
                                                <img className={`search_icon ${style['search_icon']}`} src={__getImage('search_icon.svg')} />
                                                <AutoComplete field="name" value={selectedUser} suggestions={filteredUserData} completeMethod={search} onChange={(e) => handleAutoCompleteChange(e)} placeholder={searchTitle} onSelect={(e) => { handleONSelect(e) }} emptyMessage="No results" />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                <div className='col-md-4 col-sm-12'>
                                    <div className='form-group'>
                                        <TableSearchComponent placeholder='Search by name' searchInput={filterParams.title} handleSearch={handleSearch} />
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-12'>
                                    <div className='form-group'>
                                        <Dropdown value={filterParams.industry} onChange={(e) => handleFilter(e.value)} options={industryMaster} optionLabel="name" optionValue='name'
                                            placeholder="Select Industry" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-3'>
                            <div className=''>
                                <div className='d-flex plr-10 justify-content-end width_100'>
                                    {/* <button type='button' className='btn custom_btn btn_next no-wrap'>
                                        <img src={__getImage('filtersearch.svg')} />
                                        Filter
                                    </button> */}
                                    <button type='button' className={`ml-10 btn custom_btn btn_transparent no-wrap`} onClick={() => { handleClearFilter() }}>
                                        <img src={__getImage('filtersearch.svg')} />
                                        &nbsp;
                                        Clear Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                {/* <DownloadExcel/> */}
                <div className={style['page_header']}>
                    <div>
                        <div className={style['page_title']}>
                            List of Job's
                        </div>
                        {isDataLoaded && filteredListData && <div className={style['count']}>{filteredListData.length} Record{filteredListData.length > 9 ? 's' : ''}</div>}
                    </div>
                    <div className='d-flex'>
                        <button type='button' className={`ml-10 ${style['btn_primary']}`} onClick={() => { ExportToExcel('xlsx', '_idJobListTable', 'Job Listing') }}>
                            <img src={__getImage('exportsquare.svg')} />
                            Export
                        </button>
                        {loginType !== 'superadmin' && <button type='button' className={`ml-10 ${style['btn_primary']}`} onClick={() => { openJobForm(null) }}>
                            <img src={__getImage('exportsquare.svg')} />
                            New
                        </button>}

                    </div>
                </div>
                <div className='table_wrapper scrollBar'>
                    <table id="_idJobListTable">
                        <thead>
                            <tr>
                                <th>
                                    <div className='content'>
                                        Job title
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Qualifications
                                    </div>
                                </th>
                                <th>

                                    <div className='content'>
                                        Industry
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Employee type
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Reporting Manager
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Salary
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Location
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Responsibilities
                                    </div>
                                </th>
                                <th>
                                    <div className='text-center content'>
                                        Action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isDataLoaded ? <tr>
                                <td colSpan={100}>
                                    <div className='content'>

                                    </div>
                                </td>
                            </tr> : <>
                                {filteredListData && filteredListData.length > 0 ? <>
                                    {filteredListData && filteredListData.slice(startIndex, maxIndex).map((ele, i) => {
                                        return (
                                            <tr key={`en_${i}`}>
                                                <td>
                                                    <Link href="/job/post/view/1">
                                                        <div className='d-flex'>
                                                            <div className='content'>
                                                                {ele.title}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.qualifications}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.industry}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.employeeType}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.reportingName}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>

                                                        {ele.minSalary}  {ele.maxSalary ? 'to' : '-'}  {ele.maxSalary}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.location}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.responsbilities}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex justify-content-center'>
                                                        {loginType !== 'superadmin' ? <div className='mr-10 pointer content' onClick={() => { openJobForm(ele) }}>
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </div> : ''}
                                                        <div className='mr-10 pointer content' onClick={() => { handleDeleteRecord(ele?.id) }}>
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                        <div className='pointer content' onClick={() => { openJobView(ele) }}>
                                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </> : <>
                                    <tr>
                                        <td colSpan={100}>
                                            <div className='content'>
                                                No records...
                                            </div>
                                        </td>
                                    </tr>
                                </>}
                            </>}
                        </tbody>
                    </table>
                </div>
                {isDataLoaded && filteredListData && filteredListData.length > 0 ? <div className='pagers'>
                    <div></div>
                    <Paging total={filteredListData.length} perPage={perPage} activePage={activePage} onPaginationCallback={handleOnPaginationCallback} />
                </div> : ''}
            </div>
        </Card>
        {statusDialogConfig && <StatusDialog body={statusDialogConfig.body} isTitle={true} isSuccess={statusDialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={statusDialogConfig.isFooter} />}
        {formDialogConfig && formDialogConfig.isOpen && <JobPostForm data={formDialogConfig} onCloseCallback={handleOnCloseCallback} />}
        {formViewDialogConfig && formViewDialogConfig.isOpen && <JobPostView data={formViewDialogConfig} onCloseCallback={handleOnCloseViewCallback} />}
        {confirmDialogConfig && confirmDialogConfig.isOpen ? <ConfirmDialog body={confirmDialogConfig?.body} isTitle={true} title={confirmDialogConfig?.title} onYesClickCallBack={handle_onYesClickCallBack} onNoClickCallBack={handle_onNoClickCallBack} /> : ''}
    </>)
}
export default JobListingComponent; 