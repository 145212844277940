export const StudentDetailData = {
    profileCompletion: '85',
    valueArr: [
        {
            data: [1, 2, 4, 5, 7, 8, 9, 1, 3, 5]
        },
        {
            data: [9, 5, 6, 7, 2, 1, 9, 7, 4, 1]
        },
        {
            data: [5, 9, 6, 8, 9, 3, 4, 2, 4, 5]
        },
        {
            data: [3, 4, 6, 7, 1, 3, 9, 1, 1, 4]
        },
        {
            data: [1, 2, 4, 5, 7, 3, 2, 7, 8, 4]
        }
    ],
    AssessmentDetailArr: [
        {
            label: 'VISUAL',
            value: 1,
            color: ''
        },
        {
            label: 'NUMERICAL',
            value: 1,
            color: ''
        },
        {
            label: 'VERBAL',
            value: 1,
            color: ''
        },
        {
            label: 'SEQUENCES',
            value: 1,
            color: ''
        },
        {
            label: 'SPATIAL',
            value: 1,
            color: ''
        },
        {
            label: 'THREE-D',
            value: 1,
            color: ''
        },
        {
            label: 'SYSTEMS',
            value: 1,
            color: ''
        },
        {
            label: 'VOCABULARY',
            value: 1,
            color: ''
        },
        {
            label: 'FIGUREWORK',
            value: 1,
            color: ''
        }
    ],
    scoreArr: [
        {
            label: 'Below Average',
            range: [1, 2],
            color: '255, 0, 0, 0.5'
        },
        {
            label: 'Average',
            range: [3, 4],
            color: '153, 102, 255, 0.5'
        },
        {
            label: 'Above Average',
            range: [5, 6],
            color: '54, 162, 235, 0.5'
        },
        {
            label: 'Well Above Average',
            range: [7, 8],
            color: '75, 192, 192, 0.5'
        },
        {
            label: 'Well Above Average',
            range: [9, 10],
            color: '60, 179, 113, 0.6'
        }
    ]
}

export const PresonalityTitlesdata = [
    {
        name: "FDAG – Director",
        key: "FDAG",
        description: "Characteristics: Factual, Deliberate, Assertive and Gregarious \n Careers: armed forces officer, bank manager, general manager, hotel manager, production manager, retail manager, transport manager"
    },
    {
        name: "FSpAG – Opportunist",
        key: "FSpAG",
        description: "Characteristics: Factual, Spontaneous, Assertive and Gregarious \n Careers: advertising executive, auctioneer, club secretary, estate agent, public relations director, politician, sports coach or manager, senior administrator, funds organizer"
    },
    {
        name: "IDAG – Coach",
        key: "IDAG",
        description: "Characteristics: Imaginative, Deliberate, Assertive and Gregarious \n Careers: doctor, osteopath, psychologist, senior nursing officer, senior teacher, social worker, youth worker"
    },
    {
        name: "ISpAG – Crusader",
        key: "ISpAG",
        description: "Characteristics: Imaginative, Spontaneous, Assertive and Gregarious \n Careers: civil rights worker, courier, beautician, demonstrator, journalist, public relations executive, drama teacher, union representative"
    },
    {
        name: "FDPG – Completer",
        key: "FDPG",
        description: "Characteristics:  Factual, Deliberate, Passive and Gregarious \n Careers:  ambulance crew, armed forces, cashier, nurse, police officer, prison officer, firefighter, guard"
    },
    {
        name: "FSpPG – Associate",
        key: "FSpPG",
        description: "Characteristics: Factual, Spontaneous, Passive and Gregarious \n Careers: air hostess or steward, bar person, dental assistant, hairdresser, play leader, junior teacher, secretary, sports or gym assistant, team leader"
    },
    {
        name: "IDPG – Confidant",
        key: "IDPG",
        description: "Characteristics:  Imaginative, Deliberate, Passive and Gregarious \n Careers: hospital porter, house parent, mental nurse, nursery teacher, remedial teacher, social worker, therapist"
    },
    {
        name: "ISpPG – Colleague",
        key: "ISpPG",
        description: "Characteristics:  Imaginative, Spontaneous, Passive and Gregarious \n Careers: counsellor, marketing assistant, nursery nurse, receptionist, retail assistant, stage hand, waiter/waitress"
    },
    {
        name: "FDASo – Arranger",
        key: "FDASo",
        description: "Characteristics:  Factual, Deliberate, Assertive and Solitary \n Careers:  barrister, police   inspector, solicitor, work   study officer, customs officer, tax inspector"
    },
    {
        name: "FSpASo – Adviser",
        key: "FSpASo",
        description: "Characteristics: Factual, Spontaneous, Assertive and Solitary \n Careers: importer/exporter, buyer, entrepreneur, commodities or futures broker, sales director, market trader, property speculator, road manager, club manager"
    },
    {
        name: "IDASo – Designer",
        key: "IDASo",
        description: "Characteristics:  Imaginative, Deliberate, Assertive and Solitary \n Careers:  analyst, architect, business consultant, inspector, journalist, librarian, social scientist, medical scientist"
    },
    {
        name: "ISpASo – Idealist",
        key: "ISpASo",
        description: "Characteristics:  Imaginative, Spontaneous, Assertive and Solitary \n Careers: architect, artist, author, chef, dancer, interior designer, musician, sculptor"
    },
    {
        name: "FDPSo – Researcher",
        key: "FDPSo",
        description: "Characteristics:  Factual, Deliberate, Passive and Solitary \n Careers:  accounting technician, actuary, archivist, auditor, driver, engineer, reinsurer, operations researcher"
    },
    {
        name: "FSpPSo – Implementer",
        key: "FSpPSo",
        description: "Characteristics:  Factual, Spontaneous, Passive and Solitary \n Careers:  accounting technician, tour guide, cook, dietician, interpreter, computer   technician, paramedic, road   patrol officer, surgeon"
    },
    {
        name: "IDPSo – Specialist",
        key: "IDPSo",
        description: "Characteristics:  Imaginative, Deliberate, Passive and Solitary \n Careers:  arborist, curator, farm worker, site worker, gardener, historian, delivery person, potter, shepherd, thatcher, saddler, gun maker, planner"
    },
    {
        name: "ISpPSo – Wanderer",
        key: "ISpPSo",
        description: "Characteristic:  Imaginative, Spontaneous, Passive and Solitary \n Careers:  bar person, dancer, disc jockey, entertainer, model, porter, production worker, shop assistant, waiter/waitress"
    },
]