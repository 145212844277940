import React from 'react';
import { __getImage, __localStorageGet, __logOut } from '../../_factory/globalService';
import { Link, NavLink } from "react-router-dom";
import { NavData } from './Data';
const SideNavBar = () => {
    const [menuData, set_menuData] = React.useState(null);
    React.useEffect(() => {
        let userDataTM = __localStorageGet('userDataTM');
        getMenuConfig(userDataTM)
    }, [])

    const getMenuConfig = (userDataTM) => {
        const type = userDataTM?.type;
        let tempMenuData = NavData[type];
        set_menuData(tempMenuData)
    }
    const getActiveNode = (item) => {
        let temp;
        const activeMenuFor = item.activeMenuFor;
        let hasUrl = window.location.hash;
        let spliturl = hasUrl && hasUrl.split('#');
        let pathUrl = spliturl && spliturl.length && spliturl[1];
        if (activeMenuFor && activeMenuFor.length > 0) {
            if (activeMenuFor.indexOf(pathUrl) > -1) {
                temp = true
            }
        }
        return temp
    }
    return (
        <>
            <div className='sideNavBarWrap'>
                <div className='top_bar d-flex'>
                    <div className='right_bar'>
                        <Link to="/">
                            <img src={__getImage('logoNew.svg')} />
                        </Link>
                    </div>
                </div>
                <div className='menu_item_wrap'>
                    <div className='menu_items'>
                        <ul className='menu_items_inner scrollBar'>
                            {menuData && menuData.length > 0 && menuData.map((item, index) => {
                                let isActive = getActiveNode(item);
                                return (
                                    <li key={`sideNav_${index}`} className={isActive ? 'active' : ''}>
                                        {item.url ? <NavLink to={item.url} className={`link_item ${({ isActive }) => isActive ? "active" : ""
                                            }`}>
                                            <div className='menu_name_cell'>
                                                <div className='menu_name'>
                                                    <div>
                                                        <span className='menu_icon'>
                                                            {item.icon ? <><i className={item.icon} aria-hidden="true"></i></> : <i className="placeholder fa fa-tachometer" aria-hidden="true"></i>}
                                                        </span>
                                                        {item.ds_nm}
                                                    </div>
                                                    {item.children && item.children.length > 0 ? <i class="fa fa-caret-down" aria-hidden="true"></i> : ''}

                                                </div>
                                            </div>
                                        </NavLink> : <div className={`link_item ${({ isActive }) => isActive ? "active" : ""
                                            }`}>
                                            <div className='menu_name_cell'>
                                                <div className='menu_name'>
                                                    <div>
                                                        <span className='menu_icon'>
                                                            {item.icon ? <><i className={item.icon} aria-hidden="true"></i></> : <i className="placeholder fa fa-tachometer" aria-hidden="true"></i>}
                                                        </span>
                                                        {item.ds_nm}
                                                    </div>
                                                    {item.children && item.children.length > 0 ? <i class="fa fa-caret-down" aria-hidden="true"></i> : ''}
                                                </div>
                                            </div>
                                        </div>}

                                        {item.children && item.children.length > 0 && <div className='menu_name_cell_dropdown'>
                                            <ul className='menu_cell_dropdown_inner'>
                                                {item.children.map((child, chi) => {
                                                    let activeChild = getActiveNode(child);
                                                    return (
                                                        <li key={`chn_${chi}`} className={`child_dropdown ${activeChild ? 'active' : ''}`}>
                                                            {child.url ? <NavLink to={child.url} className={`link_item ${({ isActive }) => isActive ? "active" : ""
                                                                }`}>
                                                                <span className='menu_icon'>
                                                                    {child.icon ? <><i className={child.icon} aria-hidden="true"></i></> : <i className="placeholder fa fa-tachometer" aria-hidden="true"></i>}
                                                                </span>
                                                                {child?.ds_nm}
                                                            </NavLink> : <div className={`link_item ${({ isActive }) => isActive ? "active" : ""
                                                                }`}>
                                                                <span className='menu_icon'>
                                                                    {item.icon ? <><i className={item.icon} aria-hidden="true"></i></> : <i className="placeholder fa fa-tachometer" aria-hidden="true"></i>}
                                                                </span>
                                                                {child?.ds_nm}
                                                            </div>}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SideNavBar;