import React from 'react';
import Card from '../../Components/Card';
import style from './rmList.module.scss';
import { __getImage, __getLoginDetail, __startEndIndex } from '../../_factory/globalService';
import { __deleteReportingManager, __httpFetchRM, __resendReportingManagerMail } from './rmService';
import Paging from '../../Components/Paging';
import ConfirmDialog from '../../Components/Dialog/ConfirmDialog';
import StatusDialog from '../../Components/Dialog/StatusDialog';
import ReportingManagerAction from './RM_Action';
import AssessmentReport from '../Enrolments/Detail/Report';
import { __httpStudentAssignmentDetail } from '../Enrolments/enrolmentService';
const ReportingManagerListComponent = () => {
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [searchParam, set_searchParam] = React.useState({});
    const [listData, set_listData] = React.useState(null);
    const [startIndex, set_startIndex] = React.useState(0);
    const [maxIndex, set_maxIndex] = React.useState(0);
    const [activePage, set_activePage] = React.useState(0);
    const [perPage] = React.useState(10);
    const [selectedId, set_selectedId] = React.useState(null);
    const [confirmDialogConfig, set_confirmDialogConfig] = React.useState(null);
    const [statusDialogConfig, set_statusDialogConfig] = React.useState(null);
    const [rmDialogConfig, set_rmDialogConfig] = React.useState(null);
    const [userInfo, set_userInfo] = React.useState(null);
    const [reportDialogConfig, set_reportDialogConfig] = React.useState(null);
    const [aptitudeCodeMaster, set_aptitudeCodeMaster] = React.useState(null);
    const [personalityCodeMaster, set_personalityCodeMaster] = React.useState(null);
    React.useEffect(() => {
        let userData = __getLoginDetail();
        set_userInfo(userData)
    }, [])
    React.useEffect(() => {
        getListingData(searchParam)
    }, [userInfo])
    React.useEffect(() => {
        const startEndIndex = __startEndIndex(activePage, perPage);
        set_startIndex(startEndIndex?.startIndex);
        set_maxIndex(startEndIndex?.endIndex)
    }, [activePage])
    const getListingData = async (payload) => {
        if (userInfo && userInfo.id) {
            let res = await __httpFetchRM(userInfo.id, payload);
            let temp = [];
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = []
            }
            set_isDataLoaded(true)
            set_listData(temp)
        } else {
            set_isDataLoaded(true)
        }
    }
    const handleAcction = (type, value) => {
        let title;
        if (type == 0) {
            title = `Add Reporting Manager`
        } else if (type == 1) {
            title = `Edit Reporting Manager`
        } else if (type == 2) {
            title = `View Reporting Manager`
        }

        let temp = {
            isOpen: true,
            title: title,
            actionType: type,
            id: value?.id || ''
        }
        set_rmDialogConfig(temp)
    }

    const handleOnPaginationCallback = (pageNumber) => {
        set_activePage(pageNumber)
    }
    const handleDeleteRecord = (id) => {
        let cDC = {
            isOpen: true,
            title: 'Message',
            body: 'Are you sure you want to delete ?'
        }
        set_selectedId(id)
        set_confirmDialogConfig(cDC)
    }
    const handle_onYesClickCallBack = () => {
        let id = selectedId;
        let postDto = {
            "id": id,
        }
        __deleteReportingManager(postDto).then(res => {
            set_confirmDialogConfig(null)
            let config = {}
            if (res && res.data) {
                if (res.data.isSuccess) {
                    config = {
                        body: res.data.responseMessage || 'User Data saved successfully',
                        isSuccess: true,
                        isFooter: false
                    }
                    set_statusDialogConfig(config);
                    getListingData(searchParam)
                    setTimeout(() => {
                        set_statusDialogConfig(null);
                    }, 2000)
                } else {
                    config = {
                        body: res.data.responseMessage || 'Something went wrong',
                        isSuccess: false,
                        isFooter: true
                    }
                    set_statusDialogConfig(config)
                }
            } else {
                config = {
                    body: 'Something went wrong',
                    isSuccess: false,
                    isFooter: true
                }
                set_statusDialogConfig(config)
            }
            set_selectedId(null)
        })
    }
    const handle_onNoClickCallBack = () => {
        set_confirmDialogConfig(null)
    }
    const handleOnClickCallBack = () => {
        set_statusDialogConfig(null)
    }
    const handleRMActionOnclick = (res) => {
        if (res) {
            let config = {}
            if (res && res.data) {
                if (res.data.isSuccess) {
                    config = {
                        body: res.data.responseMessage || 'User Data saved successfully',
                        isSuccess: true,
                        isFooter: false
                    }
                    set_statusDialogConfig(config);
                    set_rmDialogConfig(null)
                    getListingData(searchParam)
                    setTimeout(() => {
                        set_statusDialogConfig(null);
                    }, 2000)
                } else {
                    config = {
                        body: res.data.responseMessage || 'User Data saved successfully',
                        isSuccess: false,
                        isFooter: true
                    }
                    set_statusDialogConfig(config);
                }
            } else {
                config = {
                    body: 'Something went wrong',
                    isSuccess: false,
                    isFooter: true
                }
                set_statusDialogConfig(config)
            }

        } else {
            set_rmDialogConfig(null)
        }
    }
    const handleResendEmail = async (id) => {
        let res = await __resendReportingManagerMail(id);
        let config = {}
        if (res && res.data) {
            if (res.data.isSuccess) {
                config = {
                    body: res.data.responseMessage || 'User Data saved successfully',
                    isSuccess: true,
                    isFooter: false
                }
                set_statusDialogConfig(config);
                setTimeout(() => {
                    set_statusDialogConfig(null);
                }, 2000)
            } else {
                config = {
                    body: res.data.responseMessage || 'User Data saved successfully',
                    isSuccess: false,
                    isFooter: true
                }
                set_statusDialogConfig(config);
            }
        } else {
            config = {
                body: 'Something went wrong',
                isSuccess: false,
                isFooter: true
            }
            set_statusDialogConfig(config)
        }
    }
    const getAssessmentDetail = (id) => {
        __httpStudentAssignmentDetail(id).then(res => {
            let temp_aptitudeCodeMaster = null;
            let temp_personalityCodeMaster = null;
            const responseData = res && res.data && res.data.responseData;
            if (responseData && responseData.length > 0) {
                responseData.map((item) => {
                    if (item) {
                        // if (item.categoryName == 'Aptitude Code') {
                        //     temp_aptitudeCodeMaster = item
                        // }
                        if (item.categoryName == 'Reporting Manager') {
                            temp_personalityCodeMaster = item
                        }
                    }
                })
                set_aptitudeCodeMaster(temp_aptitudeCodeMaster);
                set_personalityCodeMaster(temp_personalityCodeMaster);
                setTimeout(() => {
                    let temp = {
                        isOpen: true
                    }
                    set_reportDialogConfig(temp)
                }, 200);
            } else {
                let config = {};
                config = {
                    isOpen: true,
                    body: 'No Assessment Taken',
                    isSuccess: true,
                    isFooter: false
                }
                set_statusDialogConfig(config)
                setTimeout(() => {
                    set_statusDialogConfig(null)
                }, 2000)
            }
        })
    }
    const openReportDialog = (id) => {
        getAssessmentDetail(id)
    }
    const closeReportMasterDialog = () => {
        set_reportDialogConfig(null)
    }
    return (<>
        <Card>
            <div className={style['enrollment_wrapper']}>
                <div className={style['page_header']}>
                    <div>
                        <div className={style['page_title']}>
                            List of Reporting Manager
                        </div>
                        {isDataLoaded && listData && <div className={style['count']}>{listData.length} Record{listData.length > 9 ? 's' : ''}</div>}
                    </div>
                    <div className='d-flex'>
                        <button type='button' className={`ml-10 ${style['btn_primary']}`} onClick={() => { handleAcction(0, null) }}>
                            <img src={__getImage('exportsquare.svg')} />
                            New
                        </button>
                    </div>
                </div>
                <div className='table_wrapper scrollBar'>
                    <table id="saleReportTable">
                        <thead>
                            <tr>
                                <th>
                                    <div className='content no-wrap'>
                                        Si. No.
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Name
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Email
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        mobileNumber
                                    </div>
                                </th>
                                <th>
                                    <div className='content text-center'>
                                        Action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isDataLoaded ? <tr>
                                <td colSpan={100}>
                                    <div className='content'>

                                    </div>
                                </td>
                            </tr> : <>
                                {listData && listData.length > 0 ? <>
                                    {listData && listData.slice(startIndex, maxIndex).map((ele, i) => {
                                        return (
                                            <tr key={`en_${i}`}>
                                                <td style={{ 'width': '100px' }}>
                                                    {i + 1}
                                                </td>
                                                <td>
                                                    <div className='content pointer' onClick={() => { openReportDialog(ele?.id) }}>
                                                        {ele?.name}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.mobileNumber}
                                                    </div>
                                                </td>
                                                <td className='text-center'>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='mr-10 pointer content' onClick={() => { handleAcction(1, ele) }}>
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </div>
                                                        <div className='mr-10 pointer content' onClick={() => { handleAcction(2, ele) }}>
                                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                                        </div>
                                                        <div className='mr-10 pointer content' onClick={() => { handleDeleteRecord(ele?.id) }}>
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                        <div className='pointer content' onClick={() => { handleResendEmail(ele.id) }}>
                                                            <button type='button' className='btn custom_btn btn_next'>Resend</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </> : <>
                                    <tr>
                                        <td colSpan={100}>
                                            <div className='content'>
                                                No records...
                                            </div>
                                        </td>
                                    </tr>
                                </>}
                            </>}
                        </tbody>
                    </table>
                </div>
                {isDataLoaded && listData && listData.length > 0 ? <div className='pagers'>
                    <div></div>
                    <Paging total={listData.length} perPage={perPage} activePage={activePage} onPaginationCallback={handleOnPaginationCallback} />
                </div> : ''}
            </div>
        </Card>
        {rmDialogConfig && rmDialogConfig.isOpen ? <ReportingManagerAction data={rmDialogConfig} userInfo={userInfo} onClickCallBack={handleRMActionOnclick} /> : ''}
        {confirmDialogConfig && confirmDialogConfig.isOpen ? <ConfirmDialog body={confirmDialogConfig?.body} isTitle={true} title={confirmDialogConfig?.title} onYesClickCallBack={handle_onYesClickCallBack} onNoClickCallBack={handle_onNoClickCallBack} /> : ''}
        {statusDialogConfig && statusDialogConfig?.isOpen && <StatusDialog body={statusDialogConfig.body} isTitle={true} isSuccess={statusDialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={statusDialogConfig.isFooter} />}

        {reportDialogConfig && <AssessmentReport aptitudeCodeMaster={aptitudeCodeMaster} personalityCodeMaster={personalityCodeMaster} motivationCodeCodeMaster={null} onCloseCallback={closeReportMasterDialog} isReportingManager={true} />}
    </>)
}
export default ReportingManagerListComponent;