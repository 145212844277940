import React from "react";
import DialogWrapper from "../../../Components/Dialog/DialogWrapper";
import { __getImage } from "../../../_factory/globalService";
import RMFormComponent from "./addRMForm";
import { __detailReportingManager } from "../rmService";
import RMViewDetailComponent from "./RMViewDetailComponent";
const ReportingManagerAction = ({ data, onClickCallBack, userInfo }) => {
    const [rmDetailData, set_rmDetailData] = React.useState()
    React.useEffect(() => {
        if (data.id) {
            getRMDetail(data.id)
        } else {
            set_rmDetailData({
                "id": "",
                "email": "",
                "name": "",
                "mobileNumber": "",
                "employerId": userInfo.id
            })
        }
    }, []);
    const getRMDetail = async (id) => {
        let res = await __detailReportingManager(id);
        if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
            let responseData = res.data.responseData;
            set_rmDetailData(responseData)
        } else {
            set_rmDetailData({
                "id": "",
                "email": "",
                "name": "",
                "mobileNumber": "",
                "employerId": userInfo.id
            })
        }
    }
    const handleOnClickCallBack = (e) => {
        onClickCallBack(e)
    }
    return (
        <>
            <DialogWrapper className={'width_900'}>
                <div className='modal-header'>
                    <h5 className="modal-title">{data.title}</h5>
                    <div className='dialog-close'>
                        <img src={__getImage('/dialog_close.svg')} onClick={() => { handleOnClickCallBack(null) }} />
                    </div>
                </div>
                <div className='modal-body'>
                    {data.actionType == 0 || data.actionType == 1 ? <>
                        <RMFormComponent formValue={rmDetailData} handleOnClickCallBack={handleOnClickCallBack} actionType={data.actionType} />
                    </> : ''}
                    {data.actionType == 2 ? <>
                        <RMViewDetailComponent formValue={rmDetailData} handleOnClickCallBack={handleOnClickCallBack} actionType={data.actionType} />
                    </> : ''}
                </div>
            </DialogWrapper>
        </>
    )
}
export default ReportingManagerAction