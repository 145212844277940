import * as React from "react";
import { __formData, __getImage } from "../../_factory/globalService";
const InputFile = ({
    placeholder,
    formData,
    isMultiple = false,
    formKey = 'fileUploadKey',
    file,
    uploadFileCallback
}) => {
    const uploadMultipleFiles = (e) => {
        let temp = {
            formData: formData,
            event: e
        }
        uploadFileCallback(temp)
    }

    return (
        <React.Fragment>
            <div className="row-same-height">
                {file && file.length > 0 ? <>
                    {file.map((item, index) => {
                        return (
                            <div className="col-same-height mr-10 align-items-center" key={`img_${index}`}>
                                <div className="form-group multi-preview">
                                    <div className="image-box"><img
                                        src={item}
                                        className="multi-img-preview"
                                        style={{ maxHeight: "100px" }}
                                    />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </> : ''}
                <div className="col-same-height align-items-center">
                    <div className="content">
                        <div className="form-group file-upload w-100">
                            <label className="form-control w-100">
                                {placeholder || 'Upload Document'}
                                <div className="upload-icon ml-20">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                </div>
                                <input
                                    type="file"
                                    className="upload"
                                    onChange={(e) => { uploadMultipleFiles(e) }}
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default InputFile;