import React from 'react';
import { __getImage } from '../../_factory/globalService';
const DialogWrapper = ({ children, className = '', isBody = '', ...props }) => {
    return (
        <div className="modal modal-wrapper opened">
            <div className={`modal-dialog ${className ? className : ''}`}>
                <div className="modal-content-border">
                    <div className="modal-content">
                        {props.isHeader && (
                            <div className="modal-header">
                                {props.title && <h5 className="modal-title">{props.title}</h5>}
                                {props.isCloseBtn && (
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => props.emitModalCloseAction()}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                )}
                            </div>
                        )}
                        {isBody ? (
                            <div className="modal-body">{children}</div>
                        ) : (
                            children
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DialogWrapper;