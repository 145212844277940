import React from 'react';
import { __getImage } from '../../../_factory/globalService';
import DialogWrapper from '../../../Components/Dialog/DialogWrapper';
import { Field, Form } from 'react-final-form';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import InputText from '../../../Components/form/InputText';
import CustomButton from '../../../Components/form/CustomButton';
import ErrorMessage from '../../../Components/form/ErrorMessage';
import { __saveMasterList } from '../masterService';
const AddMasterFormComponent = ({ data, onCloseCallback }) => {
    const [initialValue, set_initialValue] = React.useState({});
    const [dialogConfig, set_dialogConfig] = React.useState(null);
    React.useEffect(() => {
        set_initialValue(data?.formValue || {})
    }, [])
    const validate = combineValidators({
        name: composeValidators(
            isRequired,
        )("Name")
    });
    const handleOnClickCallBack = (e) => {
        if (onCloseCallback) {
            onCloseCallback(e)
        }
    }
    const onSubmit = async (values) => {
        __saveMasterList(values).then(res => {
            if (res && res.data && res.data.isSuccess) {
                let config = {
                    body: res?.data?.responseMessage || 'User Data saved successfully',
                    isSuccess: true,
                }
                set_dialogConfig(config)
                setTimeout(() => {
                    set_dialogConfig(null)
                    handleOnClickCallBack({
                        isReload: true
                    })
                }, 3000)
            } else {
                let config = {
                    body: 'Something went wrong',
                    isSuccess: false,
                }
                set_dialogConfig(config)
            }
        })
    }
    return (<>
        <DialogWrapper className={'width_500'}>
            <div className='modal-header'>
                <h5 className="modal-title">{data?.title}</h5>
                <div className='dialog-close'>
                    <img src={__getImage('/dialog_close.svg')} onClick={(e) => { handleOnClickCallBack(null) }} />
                </div>
            </div>
            <div className='modal-body'>
                <div className='form_wrapper'>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={initialValue || {}}
                        validate={validate}
                        render={({
                            handleSubmit,
                            submitError,
                            values,
                            dirtySinceLastSubmit,
                        }) => (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <Field
                                                type="text"
                                                name="name"
                                                component={InputText}
                                                placeholder="Name"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className=" text-right">
                                        {submitError && !dirtySinceLastSubmit && (
                                            <ErrorMessage
                                                error={submitError}
                                                text="Error during submit your form."
                                            />
                                        )}
                                        <div className='form-group txt-center'>
                                            <CustomButton type={'submit'} text='Submit' className='btn_next' />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
        </DialogWrapper>
    </>)
}
export default AddMasterFormComponent;