import React from 'react';
import { __getImage, __getImageurl, __getLoginDetail, __logOut } from '../../_factory/globalService';
import { useNavigate } from 'react-router-dom';
import ProfileComponent from '../../Pages/Profile';
import { __httpProfileGet } from '../../Pages/Profile/profileService';
import { connect } from 'react-redux';
import { _ProfileAction } from '../../_store/Profile/profile.action';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
const Header = ({ ...props }) => {
    const { _ProfileDetailReducer = {}, showSideMenu = {} } = props;
    const navigate = useNavigate();
    const [profileDialogConfig, set_profileDialogConfig] = React.useState(null);
    React.useEffect(() => {
        const loginDetail = __getLoginDetail();
        getUserProfile(loginDetail?.id)
    }, []);

    const getUserProfile = async (id) => {
        let res = await __httpProfileGet(id);
        if (res && res.data && res.data.isSuccess) {
            let responseData = res.data.responseData;
            props.dispatch(_ProfileAction({ ...responseData }));
        }
    }
    const HandleLogOut = () => {
        props.dispatch(_ProfileAction(null));
        __logOut('userDataTM')
        navigate('/');
    }
    const profileDialogOpen = () => {
        let data = {
            isOpen: true,
            type: _ProfileDetailReducer.type,
            title: `Edit ${_ProfileDetailReducer.type} profile`
        }
        set_profileDialogConfig(data)
    }
    const profileDialogClose = () => {
        let data = {
            isOpen: false,
            type: _ProfileDetailReducer.type
        }
        set_profileDialogConfig(data)
    }
    const renderImage = () => {
        if (_ProfileDetailReducer?.profilePic) {
            let url = __getImageurl('user_profile', _ProfileDetailReducer?.profilePic)
            return (<img className='profile_image' src={url} />)
        } else {
            return (<i class="fa fa-user" aria-hidden="true" style={{ 'font-size': '30px' }}></i>)
        }
    }
    return (
        <>
            <div className='header_wrapper'>
                <div className='header_wrapper_content'>
                    <div className='top_header_wrapper d-flex justify-content-between'>
                        <div >
                            
                            <div className='isMobileNav'>
                                <Button icon="pi pi-align-justify" style={{ backgroundColor: 'rgba(248, 136, 103, 0.5)' }} onClick={() =>
                                    showSideMenu()
                                } />
                            </div>
                        </div>
                        <div className='user_profile_wrap'>
                            <div className='d-flex align-items-center top-content'>
                                {_ProfileDetailReducer ? <>
                                    <div className='mr-10'>
                                        <p className='user_name'>{_ProfileDetailReducer?.name}</p>
                                        <p className='user_profile'>{_ProfileDetailReducer?.type}</p>
                                    </div>
                                    {renderImage()}
                                </> : ''}
                            </div>
                            <div className='profile_dropdown_menu'>
                                <ul>
                                    <li>
                                        <div className='pointer link' onClick={() => { profileDialogOpen() }}>
                                            <img src={__getImage('menuEditProfile.svg')} />
                                            Edit Profile
                                        </div>
                                    </li>
                                    <li>
                                        <div className='pointer link' onClick={() => { HandleLogOut() }}>
                                            <img src={__getImage('menuLogOut.svg')} />
                                            Log Out
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {profileDialogConfig && profileDialogConfig.isOpen && <ProfileComponent data={profileDialogConfig} userData={_ProfileDetailReducer} onCloseCallback={profileDialogClose} />}
        </>
    )
}
const mapStateToProps = (state) => {
    const { _ProfileDetailReducer } = state.profile;
    return {
        _ProfileDetailReducer,
    }
}
export default connect(mapStateToProps, null)(Header);
