import React from 'react';
const PersonalityCodeTable = ({ personalityCodeMaster, renderValue, renderDiffValue }) => {
    return (
        <>
            {personalityCodeMaster?.categoryName}
            <br /><br />
            <div className='table_wrapper auto'>
                <table>
                    <thead>
                        <tr>
                            <th className="text-center">Total Scores</th>
                            <th className="text-center"></th>
                            <th className="text-center">Dominant Letter</th>
                            <th className="text-center">Score Difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">
                                SO = {renderValue('SO')}
                            </td>
                            <td className="text-center">
                                G = {renderValue('G')}
                            </td>
                            <td className="text-center">

                                {renderValue('SO') > renderValue('G') ? 'SO' : 'G'}
                            </td>
                            <td className="text-center">
                                {renderValue('SO') > renderValue('G') ? renderDiffValue('SO', 'G') : renderDiffValue('G', 'SO')}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                A = {renderValue('A')}
                            </td>
                            <td className="text-center">
                                P = {renderValue('P')}
                            </td>
                            <td className="text-center">

                                {renderValue('A') > renderValue('P') ? 'A' : 'P'}
                            </td>
                            <td className="text-center">
                                {renderValue('A') > renderValue('P') ? renderDiffValue('A', 'P') : renderDiffValue('P', 'A')}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                I = {renderValue('I')}
                            </td>
                            <td className="text-center">
                                F = {renderValue('F')}
                            </td>
                            <td className="text-center">
                                {renderValue('I') > renderValue('F') ? 'I' : 'F'}

                            </td>
                            <td className="text-center">
                                {renderValue('I') > renderValue('F') ? renderDiffValue('I', 'F') : renderDiffValue('F', 'I')}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                SP = {renderValue('S')}
                            </td>
                            <td className="text-center">
                                D = {renderValue('D')}
                            </td>
                            <td className="text-center">
                                {renderValue('S') > renderValue('D') ? 'SP' : 'D'}
                            </td>
                            <td className="text-center">
                                {renderValue('S') > renderValue('D') ? renderDiffValue('D', 'D') : renderDiffValue('D', 'S')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default PersonalityCodeTable;